.analyticsRainfall {
  position: relative;
  margin-top: 1%;
  margin-right: 16px;
  height: 279px;
  background-color: #fff;
  padding: 15px 15px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;
}

#month {
  width: 65%;
  overflow: scroll;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;

  overflow-x: hidden;
  overflow-y: hidden;
  text-align: center;

  padding: 0 18px;
}

.month-cell {
  text-align: left;
  -webkit-flex-basis: 20%;
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5% 0;
}

.legend-font {
  font-size: 0.6rem;
}

/* MEDIA QUERIES STARTS FROM HERE */

@media screen and (max-width: 1250px) {
  .month-cell {
    font-size: 0.6rem;
  }

  .legend-font {
    font-size: 0.5rem;
  }

  .analyticsRainfall {
    padding: 15px 12px;
    height: 215px;
  }
}

@media screen and (max-width: 950px) {
  .analyticsRainfall {
    margin-right: unset;
  }
}

@media screen and (min-width: 1700px) {
  .analyticsRainfall {
    height: 285px;
  }
}

@media screen and (min-width: 1900px) {
  .analyticsRainfall {
    height: 310px;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 768px) {
  .analyticsRainfall {
    margin-bottom: 16px;
  }
}
