.landuseContentRightTop {
  background-color: #fff;
  height: 240px;
  border-radius: 12px;
  padding: 10px 20px;
  box-shadow: 0px 3px 6px #00000029;
}

.landuseContentRightTopHeadKey {
  font-size: 0.85rem;
}

.landuseContentRightTopHeadValue {
  font-size: 1.1rem;
  font-weight: 600;
}

.landuseContentRightTopChart {
  display: flex;
}

.landuseContentRightTopChartLeft {
  width: 35%;
}

.landuseContentRightTopChartLeftTop {
  font-size: 0.7rem;
  margin-top: 20%;
  margin-bottom: 7%;
}

.landuseContentRightTopChartLeftTop .value {
  font-size: 0.9rem;
  font-weight: 600;
}

.dotArea {
  height: 10px;
  width: 10px;
  background-color: #33644e;
  border-radius: 50%;
  display: inline-block;
}

.landuseContentRightTopChartLeftBottom {
  font-size: 0.7rem;
}

.landuseContentRightTopChartLeftBottom .value {
  font-size: 0.9rem;
  font-weight: 600;
}

.dotRequirement {
  height: 10px;
  width: 10px;
  background-color: #72c8d7;
  border-radius: 50%;
  display: inline-block;
}

.landuseContentRightTopChartRight {
  width: 75%;
  height: 205px;
  float: right;
}

/* // MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .landuseContentRightTop {
    height: 190px;
  }

  .landuseContentRightTopHeadKey {
    font-size: 0.68rem;
  }

  .landuseContentRightTopHeadValue {
    font-size: 0.9rem;
  }

  .landuseContentRightTopChartLeft {
    width: 25%;
  }

  .landuseContentRightTopChartLeftTop {
    font-size: 0.65rem;
  }
  .landuseContentRightTopChartLeftTop .value {
    font-size: 0.75rem;
  }

  .landuseContentRightTopChartLeftBottom {
    font-size: 0.65rem;
  }
  .landuseContentRightTopChartLeftBottom .value {
    font-size: 0.75rem;
  }

  .landuseContentRightTopChartRight {
    height: 160px;
  }
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .landuseContentRightTop {
    height: 210px;
  }

  .landuseContentRightTopHeadKey {
    font-size: 0.75rem;
  }

  .landuseContentRightTopHeadValue {
    font-size: 1rem;
  }

  .landuseContentRightTopChartLeft {
    width: 30%;
  }

  .landuseContentRightTopChartLeftTop {
    font-size: 0.65rem;
  }

  .landuseContentRightTopChartLeftTop .value {
    font-size: 0.8rem;
  }

  .landuseContentRightTopChartLeftTop {
    font-size: 0.7rem;
  }

  .landuseContentRightTopChartLeftTop .value {
    font-size: 0.85rem;
  }

  .landuseContentRightTopChartLeftBottom {
    font-size: 0.7rem;
  }

  .landuseContentRightTopChartLeftBottom .value {
    font-size: 0.85rem;
  }

  .landuseContentRightTopChartRight {
    height: 180px;
  }
}

@media screen and (min-width: 1700px) {
  .landuseContentRightTop {
    height: 250px;
  }
  .landuseContentRightTopHeadKey {
    font-size: 0.95rem;
  }

  .landuseContentRightTopHeadValue {
    font-size: 1.2rem;
  }

  .landuseContentRightTopChartLeft {
    width: 30%;
  }

  .landuseContentRightTopChartLeftTop {
    font-size: 0.8rem;
  }

  .landuseContentRightTopChartLeftTop .value {
    font-size: 1rem;
  }

  .landuseContentRightTopChartLeftTop {
    font-size: 0.85rem;
  }

  .landuseContentRightTopChartLeftTop .value {
    font-size: 0.95rem;
  }

  .landuseContentRightTopChartLeftBottom {
    font-size: 0.85rem;
  }

  .landuseContentRightTopChartLeftBottom .value {
    font-size: 0.95rem;
  }

  .landuseContentRightTopChartRight {
    height: 210px;
  }
}

@media screen and (min-width: 1900px) {
  .landuseContentRightTop {
    height: 280px;
  }

  .landuseContentRightTopHeadKey {
    font-size: 1.05rem;
  }

  .landuseContentRightTopHeadValue {
    font-size: 1.3rem;
  }

  .landuseContentRightTopChartLeft {
    width: 30%;
  }

  .landuseContentRightTopChartLeftTop {
    font-size: 0.85rem;
  }

  .landuseContentRightTopChartLeftTop .value {
    font-size: 0.95rem;
  }

  .landuseContentRightTopChartLeftTop {
    font-size: 0.95rem;
  }

  .landuseContentRightTopChartLeftTop .value {
    font-size: 1rem;
  }

  .landuseContentRightTopChartLeftBottom {
    font-size: 0.85rem;
  }

  .landuseContentRightTopChartLeftBottom .value {
    font-size: 0.95rem;
  }

  .landuseContentRightTopChartRight {
    height: 240px;
  }
}

/* Adding breakpoints for tablets view  */
@media only screen and (max-width: 767px) {
  .landuseContentRightTop {
    height: auto;
  }

  .landuseContentRightTopChart {
    justify-content: space-between;
  }

  .landuseContentRightTopChartLeft {
    width: 100%;
  }

  .landuseContentRightTopChartRight {
    width: 100%;
    margin-top: 20px;
  }

  .landuseContentRightTopChart {
    flex-direction: column-reverse;
  }

  .landuseContentRightTopChartLeftTop {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-right: 1.8rem;
  }

  .landuseContentRightTopChartLeft {
    display: flex;
    align-items: center;
    margin-left: 1.2rem;
  }

  .value {
    margin-left: 0.9rem;
  }
}
