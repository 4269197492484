.analyticsWaterAvailability {
  position: relative;
  height: 220px;
  margin-top: 2%;
  background-color: #fff;
  padding: 15px 15px;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;
}

.waterAvailabilityChartHeight {
  height: 175px;
}

.heading-txt {
  font-size: 0.8rem;
  margin-bottom: 3px;
}

@media screen and (max-width: 1250px) {
  .heading-txt {
    font-size: 0.65rem;
  }
}

.value-txt {
  font-size: 0.9rem;
}

@media screen and (max-width: 1250px) {
  .value-txt {
    font-size: 0.75rem;
  }
}

.analyticsWaterAvailabilityChart {
  font-size: 0.65rem;
}

/* MEDIA QUERIES STARTS FROM HERE  */
@media screen and (max-width: 1250px) {
  .analyticsWaterAvailability {
    padding: 15px 12px;
    height: 205px;
  }

  .analyticsWaterAvailabilityChart {
    font-size: 0.55rem;
  }
}

@media screen and (max-width: 950px) {
  .analyticsWaterAvailability {
    margin-right: unset;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1250px) {
  .analyticsWaterAvailabilityChart {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 1700px) {
  .analyticsWaterAvailability {
    height: 265px;
  }

  .analyticsWaterAvailabilityChart {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1900px) {
  .analyticsWaterAvailability {
    height: 290px;
  }

  .analyticsWaterAvailabilityChart {
    font-size: 0.8rem;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 768px) {
  .waterAvailabilityChartHeight {
    height: 160px;
  }

  .analyticsWaterAvailability {
    margin-top: unset;
  }
}
