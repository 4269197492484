.header {
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 26px;
}

.content {
  background-color: #e2faff;
  border-radius: 15px;
  border: 1px solid #abf1ff;
  padding: 10px;
  margin: 7px;
}

.divider {
  margin: 20px 10px;
  border: 1px solid #81e1f7;
}
