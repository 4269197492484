.savedScenarioWrapper {
  position: relative;
  margin-top: 1.5vw;
  margin-left: 18%;
  margin-right: 40px;
}
@media screen and (max-width: 950px) {
  .savedScenarioWrapper {
    margin-left: 23%;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: 1.2fr 1.4fr 1.7fr;
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 1em;
  justify-items: stretch;
  align-items: stretch;
}
.item {
  background: #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 12px;
}
.item1 {
  grid-row: 1/3;
  padding: unset;
}
.item2 {
  grid-row: 3/5;
  font-size: 19px;
}
.item3 {
  grid-row: 5/6;
  font-size: 19px;
}
.item4 {
  font-size: 18px;
}
.item4,
.item5 {
  grid-row: 1/6;
}
/* ${'' RIGHT SIDE STYLING } */
.third-item-three .third-less-padding {
  display: flex;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  padding: 0px 0;
  width: '100%';
}
.third-item-three .less-padding-top-right {
  display: flex;
  justify-content: space-between;
}
.third-item-three .less-padding-top-right .less-padding-right-two {
  text-align: center;
}
.third-item-three .bar-div {
  display: flex;
  margin-bottom: 30px;
}
.third-item-three .bar-div-suitebar {
  width: 40.33%;
}
.third-item-three .right-two-ones {
  display: flex;
  align-items: center;
  width: 37%;
}
.third-item-three .right-two-ones .twelve {
  width: 50%;
}
.third-item-three .right-two-ones .twelve p {
  font-weight: bold;
}
.third-item-three .right-two-ones .twelve-two {
  font-weight: bold;
  width: 50%;
  display: flex;
  justify-content: center;
}
/* ${'' Worked on font sizes} */
.item-one-div .first-box-demand,
.item-one-div .first-box-demand-value,
.item-one-div .first-box-available,
.item-one-div .first-box-available-value,
.item-one-div .first-box-balance {
  font-size: 19px;
}
.item-one-div .first-box-balance-value {
  font-size: 20px;
}
.item2 .months-remaining {
  font-size: 22px;
}
.total-water-saved {
  font-size: 22px;
}
.runoff-to-be-arrested {
  font-size: 20px;
  margin-top: 45px;
}
.item5 .demand-side-heading {
  font-size: 18px;
  margin-bottom: 30px;
}
.item5 .crop-list-heading {
  font-size: 16px;
  margin-bottom: 10px;
}
.item5 .order-most-least-heading {
  font-size: 16px;
}
.crop-name-mapped {
  font-size: 14px;
}
.mapped-absolute-drip {
  font-size: 12px;
}

/* ADDED NEW  */
.groundwaterLastText {
  margin: 30px 0 20px 0;
}

.supplySideSavedScenarioBox {
  margin-top: 70px;
}

.percIncreaseWater {
  margin-top: 60px;
}

.dripPercentage {
  font-size: 14px;
}

/* ${'' MEDIA QUERIES STARTS FROM HERE ----------------} */
@media screen and (max-width: 1250px) {
  .item-one-div .first-box-demand,
  .item-one-div .first-box-demand-value,
  .item-one-div .first-box-available,
  .item-one-div .first-box-available-value,
  .item-one-div .first-box-balance {
    font-size: 16px;
  }
  .item-one-div .first-box-balance-value {
    font-size: 17px;
  }
  .item2 {
    font-size: 18px;
  }
  .item2 .months-remaining {
    font-size: 18px;
  }
  .item3 {
    font-size: 18px;
  }
  .item3 .total-water-saved {
    font-size: 18px;
  }
  .item4 {
    font-size: 16px;
  }
  .runoff-to-be-arrested {
    font-size: 17px;
  }
  .item5 .demand-side-heading {
    font-size: 16px;
  }
  .item5 .crop-list-heading {
    font-size: 14px;
  }
  .item5 .order-most-least-heading {
    font-size: 14px;
  }
  .third-less-padding {
    font-size: 15px;
  }
  .bar-div-container {
    font-size: 14px;
  }
  .crop-name-mapped {
    font-size: 13px;
  }
  .mapped-absolute-drip {
    font-size: 11px;
  }
  .total-stat {
    font-size: 14px;
  }
  .total-values-bottom {
    font-size: 14px;
  }
  .first-box-balance-text {
    font-size: 14px;
  }
}
@media screen and (max-width: 1100px) {
  /* ${'' FINALIZING FONT SIZES } */
  .grid-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .item1,
  .item2,
  .item3 {
    grid-row: unset;
  }
  .item1 {
    height: 200px;
  }
  .item3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .item4 {
    grid-row: unset;
    grid-column: 1/2;
    width: 300px;
  }
  .item5 {
    grid-row: unset;
    grid-column: 2/4;
  }
  .item-one-div .first-box-demand,
  .item-one-div .first-box-demand-value,
  .item-one-div .first-box-available,
  .item-one-div .first-box-available-value,
  .item-one-div .first-box-balance {
    font-size: 14px;
  }
  .item-one-div .first-box-balance-value {
    font-size: 15px;
  }
  .item2 {
    font-size: 16px;
  }
  .item2 .months-remaining {
    font-size: 16px;
  }
  .item3 {
    font-size: 16px;
  }
  .item3 .total-water-saved {
    font-size: 16px;
  }
  .item4 {
    font-size: 15px;
  }
  .runoff-to-be-arrested {
    font-size: 16px;
  }
  .item5 .demand-side-heading {
    font-size: 15px;
  }
  .item5 .crop-list-heading {
    font-size: 13px;
  }
  .item5 .order-most-least-heading {
    font-size: 13px;
  }
  .third-less-padding {
    font-size: 14px;
  }
  .bar-div-container {
    font-size: 13px;
  }
  .crop-name-mapped {
    font-size: 12px;
  }
  .mapped-absolute-drip {
    font-size: 11px;
  }
  .total-stat {
    font-size: 13px;
  }
  .total-values-bottom {
    font-size: 13px;
  }
}
@media screen and (max-width: 950px) {
  .item4 {
    width: 250px;
  }
  .first-box-balance-text {
    font-size: 12px;
  }
  .item2,
  .item3,
  .item4 {
    font-size: 13px;
  }
}
@media screen and (min-width: 1700px) {
  .grid-container {
    height: 700px;
  }
}

/* MOBILE SCREENS  */

@media screen and (max-width: 768px) {
  .savedScenarioWrapper {
    position: relative;
    margin-top: 1.5vw;
    margin-left: 18px;
    margin-right: 18px;
  }

  .item1 {
    height: 175px;
  }

  .item-one-div .first-box-demand,
  .item-one-div .first-box-demand-value,
  .item-one-div .first-box-available,
  .item-one-div .first-box-available-value,
  .item-one-div .first-box-balance {
    font-size: 0.75rem;
  }

  .first-box-balance-text {
    font-size: 0.7rem;
  }

  .groundwaterLastText {
    margin: 10px 0 15px 0;
    font-size: 0.7rem;
  }
  .totalWaterSavedText {
    font-size: 0.7rem;
    margin: 10px 0 15px 0;
  }

  .item2 .months-remaining {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }

  .item3 .total-water-saved {
    font-size: 0.85rem;
  }

  .supplySideSavedScenarioBox {
    margin-top: 40px;
  }

  .percIncreaseWater {
    margin-top: 30px;
  }

  .runoff-to-be-arrested {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 0.85rem;
  }

  .supplySideHeading {
    font-size: 0.85rem;
  }

  .item4 {
    font-size: 0.75rem;
  }

  .item5 .demand-side-heading {
    font-size: 0.85rem;
    margin-bottom: 15px;
  }

  .item5 .crop-list-heading {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }
  .item5 .order-most-least-heading {
    font-size: 0.7rem;
  }

  .third-less-padding {
    font-size: 0.75rem;
  }

  .bar-div-container {
    font-size: 0.7rem;
  }
  .crop-name-mapped {
    font-size: 0.7rem;
  }
  .mapped-absolute-drip {
    font-size: 0.65rem;
  }

  .dripPercentage {
    font-size: 0.65rem;
  }

  .total-stat {
    font-size: 0.75rem;
  }
  .total-values-bottom {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .item1 {
    grid-row: unset;
    grid-column: 1/4;
  }
  .item2 {
    grid-row: unset;
    grid-column: 1/2;
  }
  .item3 {
    grid-row: unset;
    grid-column: 2/4;
  }

  .item4 {
    width: unset;
  }

  .item4,
  .item5 {
    grid-row: unset;
    grid-column: 1/4;
  }

  .item5 {
    margin-bottom: 10px;
  }
}
