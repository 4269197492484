.main-wrapper-here {
  margin-left: 18%;
  margin-right: 40px;
  margin-top: 1.5vw;
}

.graph-main-wrapper {
  margin-left: 18%;
}

.chartLeftMargin {
  margin-left: 60px;
}

/* ADDED NEW  */
.plansSavedText {
  margin-right: 50px;
  color: #3883e6;
  font-style: italic;
}

.cropDivBottom,
.supplyDemandBottom {
  margin-right: 100px;
  display: flex;
  align-items: center;
}

.bottomValuesContainer {
  margin-left: 50px;
  margin-right: 150px;
  margin-top: 30px;
}

.scenarioFilterWarning {
  margin-left: 20px;
  color: red;
  font-size: 15px;
}

.filtersPosition {
  margin-left: 50px;
  background: #3883e6;
  width: 70px;
  text-align: center;
  border-radius: 10px;
  padding: 4px 4px;
  color: #ffffff;
  cursor: pointer;
}

.noOfPlansSaved {
  display: none;
}

@media screen and (max-width: 950px) {
  .main-wrapper-here {
    margin-left: 23%;
  }
  .graph-main-wrapper {
    margin-left: 23%;
  }

  .chartLeftMargin {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .scenario-bottom-values {
    font-size: 14px;
  }
}
@media screen and (max-width: 1160px) {
  .scenario-bottom-values {
    font-size: 11px;
  }
}

@media screen and (min-width: 1700px) {
}

/* MOBILE SCREENS  */

@media screen and (max-width: 768px) {
  .main-wrapper-here {
    margin-left: 18px;
    margin-right: 18px;
  }
  .graph-main-wrapper {
    margin-left: 18px;
    margin-right: 18px;
    overflow: scroll;
  }

  .incDec {
    display: none;
  }

  .plansSavedDsk {
    display: none;
  }

  .noOfPlansSaved {
    display: block;
    margin-bottom: 10px;
    font-size: 0.7rem;
    text-align: right;
  }
}

@media screen and (max-width: 600px) {
  .incDec {
    display: none;
  }

  .scenario-bottom-values {
    font-size: 0.55rem;
  }

  .plansSavedText {
    margin-right: 30px;
    color: #3883e6;
    font-style: italic;
  }

  .cropDivBottom,
  .supplyDemandBottom {
    margin-right: 30px;
    display: flex;
    align-items: center;
  }

  .bottomValuesContainer {
    margin-left: 30px;
    margin-right: 0;
    margin-top: 30px;
  }

  .scenarioFilterWarning {
    font-size: 11px;
  }

  .filtersPosition {
    width: 60px;
    padding: 4px 4px;
    font-size: 11px;
    margin-bottom: 10px;
  }

  .chartLeftMargin {
    margin-left: 10px;
  }
}
