:root {
  --page-height: 88vh;
  --layers-height: 85vh;
}

.webGisPage {
  height: var(--page-height);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.mapContainer {
  flex: 1;
  width: 100%;
  height: 100%;
  margin-left: 16%;
}

@media screen and (max-width: 950px) {
  .mapContainer {
    margin-left: 20%;
  }
}

@media screen and (max-width: 768px) {
  .mapContainer {
    margin-left: 0%;
  }
}
