.analyticsAlert {
  position: relative;
  height: 547px;
  width: 95%;
  margin-top: 1.35%;
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;
  height: auto;
}

.new-header-values {
  font-size: 14px;
}

/* MEDIA QUERIES STARTS FROM HERE */

@media screen and (max-width: 1250px) {
  .analyticsAlert {
    /* height: 467px; */
    padding: 15px 15px;
  }

  .new-header-values {
    font-size: 12.5px;
  }
}

@media screen and (max-width: 950px) {
  .analyticsAlert {
    /* height: 440px; */
  }
}

@media screen and (min-width: 1700px) {
  .analyticsAlert {
    /* height: 600px; */
  }

  .new-header-values {
    font-size: 18px;
  }
}

@media screen and (min-width: 1900px) {
  .analyticsAlert {
    /* height: unset; */
  }

  .new-header-values {
    font-size: 20px;
  }
}

/* tablet view  */
@media screen and (max-width: 768px) {
  .analyticsAlert {
    height: 520px;
    margin-top: unset;
  }
}

@media only screen and (max-width: 600px) {
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .analyticsAlert {
    box-sizing: border-box;
    width: 100%;
    margin-top: unset;
    min-height: 600px;
    height: auto;
  }
}
