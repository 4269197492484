.landuse {
  position: relative;
  margin-top: 1.5vw;
  margin-left: 18%;
  margin-right: 40px;
}

.landuseNavbar {
  display: flex;
  justify-content: space-between;
}

.landuseNavbarBack {
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.landuseNavbarBack:hover {
  color: #3884e6;
}

.landuseContent {
  display: flex;
  margin-top: 2%;
}

.landuseContentRight {
  width: 50%;
}

.landuseContentRightBottom {
  margin-top: 2%;
  background-color: #fff;
  height: 238px;
  border-radius: 12px;
  padding: 10px 20px;
  box-shadow: 0px 3px 6px #00000029;
}

.landuseContentRightBottom table {
  border-collapse: collapse;
  width: 100%;
}

.landuseContentRightBottom th {
  padding: 8px 3px;
  font-size: 0.8rem;
  text-align: left;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  text-align: center;
}

.landuseContentRightBottom td {
  text-align: left;
  border: 1px solid #f3f3f3;
  padding: 8px 3px;
  font-size: 0.75rem;
  text-align: center;
}

.landuseContentRightBottom tr:nth-child(odd) {
  background-color: #f3f3f3;
}

.landuseContentRightBottom .intensive {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #3883e6;
  font-weight: 600;
  color: #fff;
}

.landuseContentRightBottom .economy {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #3883e6;
  font-weight: 600;
  background-color: #fff;
}

.landuseContentRightBottom button {
  float: right;
  padding: 4px 5px;
  border: none;
  margin-bottom: 1%;
  font-size: 0.7rem;
  box-shadow: 0px 3px 6px #00000029;
}

/* // MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .landuse {
    margin-left: 18.5%;
  }

  .landuseNavbarBack {
    font-size: 0.75rem;
  }

  .landuseContent {
    margin-top: 1%;
  }
  .landuseContentRightBottom {
    height: 163px;
  }

  .landuseContentRightBottom .economy,
  .landuseContentRightBottom .intensive {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 950px) {
  .landuse {
    margin-left: 23%;
    margin-right: 2.4%;
  }

  .landuseContent {
    flex-direction: column;
  }

  .landuseContentRight {
    width: 100%;
  }

  .landuseContentRightBottom {
    height: 200px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .landuseNavbarBack {
    font-size: 0.85rem;
  }

  .landuseContent {
    margin-top: 1%;
  }

  .landuseContentRightBottom {
    margin-top: 10px;
    height: 245px;
  }
}

@media screen and (min-width: 1700px) {
  .landuseNavbarBack {
    font-size: 1.1rem;
  }

  .landuseContent {
    margin-top: 2%;
  }

  .landuseContentRightBottom {
    margin-top: 10px;
    height: 300px;
  }

  .landuseContentRightBottom button {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1900px) {
  .landuseNavbarBack {
    font-size: 1.25rem;
  }

  .landuseContent {
    margin-top: 2%;
  }

  .landuseContentRightBottom {
    height: 347px;
  }

  .landuseContentRightBottom button {
    font-size: 0.9rem;
  }
}

/* Adding breakpoints for tablets view  */
@media only screen and (max-width: 767px) {
  .landuse {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;
  }

  .landuseNavbarBack {
    margin: 7px 0px;
  }

  .landuseContentRightBottom th {
    font-size: 0.65rem;
    padding: 6px 3px;
  }

  .landuseContentRightBottom td {
    font-size: 0.6rem;
  }
  .landuseContentRightBottom {
    margin-bottom: 20px;
  }
}
