.talukaPageProgramHead {
  margin-bottom: 2%;
  font-weight: 600;
}

.talukaProgramStats {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
  width: 97%;
  height: 150px;
}

@media screen and (max-width: 1250px) {
  .talukaPageProgramHead {
    font-size: 0.8rem;
  }
  .grid-program-section {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .talukaPageProgramHead {
    font-size: 0.85rem;
  }
}

@media screen and (min-width: 1700px) {
  .talukaPageProgramHead {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1900px) {
  .talukaPageProgramHead {
    font-size: 1.2rem;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .talukaPageProgramHead {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    margin-bottom: 0.5rem;
  }
}
