.surfacewater {
  position: relative;
  margin-top: 1.5vw;
  margin-left: 18%;
  margin-right: 40px;
}

.surfacewaterNavbar {
  display: flex;
  justify-content: space-between;
}

.surfacewaterNavbarBack {
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.surfacewaterNavbarBack:hover {
  color: #3884e6;
}

.surfacewaterContent {
  display: flex;
  margin-top: 2%;
}

.surfacewaterContentRight {
  width: 50%;
}

.surfacewaterContentRightTop {
  background-color: #fff;
  height: 245px;
  border-radius: 12px;
  padding: 10px 20px;
  box-shadow: 0px 3px 6px #00000029;
}

.surfacewaterContentRightTopHeadKey {
  font-size: 0.85rem;
}

.surfacewaterContentRightTopHeadValue1 {
  font-size: 1.1rem;
  font-weight: 600;
}

.surfacewaterContentRightTopHeadValue2 {
  font-size: 0.7rem;
  margin-top: 3.3%;
  margin-bottom: 3.3%;
}

.surfacewaterContentRightTopBottom {
  display: flex;
  margin-top: 1%;
  width: 100%;
  height: 150px;
  justify-content: space-around;
}

.surfacewaterContentRightTopBottom div {
  width: 25%;
}

.surfacewaterContentRightBottom {
  margin-top: 2%;
  background-color: #fff;
  height: 242px;
  border-radius: 12px;
  padding: 10px 20px;
  box-shadow: 0px 3px 6px #00000029;
}

.landuseNavbarBack {
  font-weight: 600;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

/* // MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .surfacewater {
    margin-left: 18.5%;
  }

  .surfacewaterNavbarBack {
    font-size: 0.75rem;
  }

  .surfacewaterContent {
    margin-top: 1%;
  }

  .surfacewaterContentRightTop {
    height: 175px;
  }

  .surfacewaterContentRightTopHeadKey {
    font-size: 0.68rem;
  }

  .surfacewaterContentRightTopHeadValue1 {
    font-size: 0.8rem;
  }

  .surfacewaterContentRightTopHeadValue2 {
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 0.57rem;
  }

  .surfacewaterContentRightTopBottom {
    height: 120px;
  }

  .surfacewaterContentRightBottom {
    margin-top: 10px;
    height: 215px;
  }
}

@media screen and (max-width: 950px) {
  .surfacewater {
    margin-left: 23%;
    margin-right: 2%;
  }

  .surfacewaterContent {
    flex-direction: column;
  }

  .surfacewaterContentRight {
    width: 100%;
  }

  .surfacewaterContentRightTop {
    height: unset;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .surfacewaterNavbarBack {
    font-size: 0.85rem;
  }

  .surfacewaterContent {
    margin-top: 1%;
  }

  .surfacewaterContentRightTop {
    height: 230px;
  }

  .surfacewaterContentRightTopHeadKey {
    font-size: 0.75rem;
  }

  .surfacewaterContentRightTopHeadValue1 {
    font-size: 0.9rem;
  }

  .surfacewaterContentRightTopHeadValue2 {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .surfacewaterContentRightTopBottom {
    height: 150px;
  }

  .surfacewaterContentRightBottom {
    margin-top: 10px;
    height: 230px;
  }
}

@media screen and (min-width: 1700px) {
  .surfacewaterNavbarBack {
    font-size: 1.1rem;
  }
  .surfacewaterContent {
    margin-top: 2%;
  }

  .surfacewaterContentRightTop {
    height: 270px;
  }

  .surfacewaterContentRightTopHeadKey {
    font-size: 0.95rem;
  }

  .surfacewaterContentRightTopHeadValue1 {
    font-size: 1.2rem;
  }

  .surfacewaterContentRightTopHeadValue2 {
    margin-top: 2.5%;
    margin-bottom: 2%;
    font-size: 0.8rem;
  }

  .surfacewaterContentRightTopBottom {
    height: 175px;
  }

  .surfacewaterContentRightBottom {
    height: 275px;
  }
}

@media screen and (min-width: 1900px) {
  .surfacewaterNavbarBack {
    font-size: 1.25rem;
  }

  .surfacewaterContent {
    margin-top: 2%;
  }

  .surfacewaterContentRightTop {
    height: 300px;
  }

  .surfacewaterContentRightTopHeadKey {
    font-size: 1.05rem;
  }

  .surfacewaterContentRightTopHeadValue1 {
    font-size: 1.3rem;
  }

  .surfacewaterContentRightTopHeadValue2 {
    margin-top: 2.5%;
    margin-bottom: 2%;
    font-size: 0.9rem;
  }

  .surfacewaterContentRightTopBottom {
    height: 195px;
  }

  .surfacewaterContentRightBottom {
    height: 322px;
  }
}

/* Adding breakpoints for tablets view  */
@media only screen and (max-width: 767px) {
  .surfacewaterContentRightBottom {
    height: auto;
    margin-bottom: 20px;
  }

  .surfacewater {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;
  }

  .surfacewaterNavbarBack {
    margin: 7px 0px;
  }
}
