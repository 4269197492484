.header {
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 26px;
}

.container {
  border-radius: 15px;
  margin: 7px;
}

.tableContainer {
  background-color: #ffebe8;
  border: 1px solid #ffcfc7;
  margin: 5% 0;
  border-radius: 15px;
}

.tableHeader {
  font-weight: bold;
  text-align: left;
  padding: 10px;
  background-color: #f8c8c1;
  border-radius: 15px 15px 0 0;
  border-bottom: 2px solid #e77261;
  font-size: 13px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.tableRow {
  display: flex;
  border-bottom: 0.5px solid #e77261;
}

.tableCellHeader {
  flex: 1;
  font-weight: 600;
  text-align: left;
  padding: 12px 10px;
  background-color: #fce5e1;
  font-size: 10px;
  text-align: center;
}

.tableCell {
  flex: 1;
  padding: 12px 10px;
  font-size: 10px;
  overflow: auto;
  margin: auto;
  text-align: center;
}

.reason {
  margin-top: 5px;
  font-size: 10px;
  font-weight: 500;
  color: #333;
}

.info {
  color: #e77261;
  font-size: 0.7rem;
}

.info:hover {
  cursor: pointer;
}

.popupContentWrapper {
  padding: 20px;
}

.popupTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px; /* Spacing below the title */
  color: #333; /* Darker color for the title */
}

.popupMessage {
  font-size: 16px;
  color: #666; /* Gray color for the message */
  margin-bottom: 20px; /* Spacing below the message */
  text-align: left; /* Align message text to the left */
}

.buttonContainer {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
}

.popupButton {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.popupButton:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
