.program-stats-container {
  height: 150px;
  padding: 15px;
}

.homeProgramStatsBoxHead {
  font-size: 0.8rem;
  font-weight: 600;
}

.homeProgramStatsBoxContent {
  text-align: center;
}

.homeProgramStatsBoxNumber {
  margin-top: 13%;
  font-size: 2rem;
  display: inline-block;
  text-align: center;
  font-weight: 700;
}

.homeProgramStatsBoxUnit {
  font-size: 0.8rem;
  font-weight: 600;
}

.statBoxLoader {
  margin-top: 20%;
}

/* MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .program-stats-container {
    height: 100px;
  }

  .homeProgramStatsBoxHead {
    font-size: 0.65rem;
  }
  .homeProgramStatsBoxNumber {
    font-size: 1.3rem;
    margin-top: 17%;
  }
  .homeProgramStatsBoxUnit {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .homeProgramStatsBoxHead {
    font-size: 0.75rem;
  }
  .homeProgramStatsBoxNumber {
    font-size: 1.7rem;

    margin-top: 17%;
  }
  .homeProgramStatsBoxUnit {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1700px) {
  .homeProgramStatsBoxHead {
    font-size: 0.9rem;
  }
  .homeProgramStatsBoxNumber {
    font-size: 2rem;
    margin-top: 13%;
  }
  .homeProgramStatsBoxUnit {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1900px) {
  .homeProgramStatsBoxHead {
    font-size: 1rem;
  }
  .homeProgramStatsBoxNumber {
    font-size: 2.2rem;
    margin-top: 13%;
  }
  .homeProgramStatsBoxUnit {
    font-size: 1.1rem;
  }
}

/* Adding breakpoints for mobile view  */
/* @media only screen and (max-width: 480px) { */
@media only screen and (max-width: 600px) {
  .program-stats-container {
    height: 5rem;
    padding: 9px;
  }

  .homeProgramStatsBoxHead {
    font-size: 0.65rem;
    font-weight: 400;
  }

  .homeProgramStatsBoxNumber {
    font-size: 0.9rem;
    margin-top: 12%;
  }

  .homeProgramStatsBoxContent {
    text-align: left;
  }

  .homeProgramStatsBoxUnit {
    font-size: 0.9rem;
  }

  .statBoxLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
}

/* FOR SMALLER MOBILE PHONES  */
@media only screen and (max-width: 340px) {
  .homeProgramStatsBoxNumber {
    font-size: 0.8rem;
    margin-top: 14%;
  }

  .homeProgramStatsBoxUnit {
    font-size: 0.8rem;
  }
}
