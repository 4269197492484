.homePageProgramHead {
  margin-bottom: 2%;
  font-weight: 600;
}

@media screen and (max-width: 1250px) {
  .homePageProgramHead {
    font-size: 0.8rem;
  }

  .grid-program-section {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .homePageProgramHead {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1700px) {
  .homePageProgramHead {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1900px) {
  .homePageProgramHead {
    font-size: 1.25rem;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .homePageProgramHead {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    margin-bottom: 0.5rem;
  }
}
