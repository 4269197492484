.talukaProgramStatsBox {
  height: 150px;
  padding: 15px;
}
.talukaProgramStatsBoxHead {
  font-size: 0.8rem;
  font-weight: 600;
}
.talukaProgramStatsBoxContent {
  text-align: center;
}
.talukaProgramStatsBoxNumber {
  margin-top: 13%;
  font-size: 2rem;
  display: inline-block;
  text-align: center;
  font-weight: 700;
}
.talukaProgramStatsBoxUnit {
  font-size: 0.8rem;
  font-weight: 600;
}
.statBoxLoader {
  margin-top: 20%;
}

/* MEDIA QUERIES STARTS FROM HERE  */
@media screen and (max-width: 1450px) {
  .talukaProgramStatsBoxNumber {
    margin-top: 17%;
  }
}
@media screen and (max-width: 1250px) {
  .talukaProgramStatsBox {
    height: 100px;
  }
  .talukaProgramStatsBoxHead {
    font-size: 0.65rem;
  }
  .talukaProgramStatsBoxNumber {
    font-size: 1.3rem;
    margin-top: 17%;
  }
  .talukaProgramStatsBoxUnit {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .talukaProgramStatsBoxHead {
    font-size: 0.75rem;
  }
  .talukaProgramStatsBoxNumber {
    font-size: 1.8rem;
  }
  .talukaProgramStatsBoxUnit {
    font-size: 0.7rem;
  }
}
@media screen and (min-width: 1700px) {
  .talukaProgramStatsBoxHead {
    font-size: 0.9rem;
  }
  .talukaProgramStatsBoxNumber {
    font-size: 2.15rem;
  }
  .talukaProgramStatsBoxUnit {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 1900px) {
  .talukaProgramStatsBoxHead {
    font-size: 1rem;
  }
  .talukaProgramStatsBoxNumber {
    font-size: 2.25rem;
  }
  .talukaProgramStatsBoxUnit {
    font-size: 1rem;
  }
}

/* Adding breakpoints for mobile view  */
/* @media only screen and (max-width: 480px) { */
@media only screen and (max-width: 600px) {
  .talukaProgramStatsBox {
    height: 5rem;
    padding: 9px;
  }

  .talukaProgramStatsBoxHead {
    font-size: 0.65rem;
    font-weight: 400;
  }

  .talukaProgramStatsBoxNumber {
    font-size: 0.9rem;
    margin-top: 12%;
  }

  .talukaProgramStatsBoxContent {
    text-align: left;
  }

  .talukaProgramStatsBoxUnit {
    font-size: 0.9rem;
  }

  .statBoxLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
}
