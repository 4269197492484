.loginForm {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 500px;
  padding: 2.5% 4% 3% 4%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  background-color: #fff;
}

.companyLogo {
  width: 25%;
  margin-left: 35%;
}

.tagLine {
  text-align: center;
  font-size: 0.6rem;
  color: #263a6a;
}

.errorMessage {
  color: #ff3333;
  margin-top: 1%;
  font-size: 0.6rem;
  text-align: center;
  font-weight: bold;
}

.message {
  margin-top: 10%;
  text-align: center;
  margin-bottom: 5%;
  font-size: 1rem;
  text-shadow: 0px 0px 1px #000000;
}

.loginLabel {
  display: block;
  margin-bottom: 0.5%;
  margin-top: 2%;
  margin-left: 2.5%;
  color: #2a86c8;
  font-size: 0.9rem;
  text-shadow: 0px 0px 1px #2a86c8;
}

.eye-icon {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
}

.eye-icon:hover {
  cursor: pointer;
}

.forgotPassword {
  margin-top: 1.5%;
  margin-right: 12%;
  font-size: 0.65rem;
  text-align: right;
  color: #2a86c8;
  width: 100%;
  text-shadow: 0px 0px 1px #2a86c8;
}

@media screen and (max-width: 1250px) {
  .tagLine {
    font-size: 0.5rem;
  }
  .errorMessage {
    font-size: 0.55rem;
  }
  .loginForm {
    width: 90%;
  }
  .message {
    font-size: 0.85rem;
  }
  .forgotPassword {
    font-size: 0.55rem;
  }
  .loginLabel {
    font-size: 0.7rem;
  }
  .eye-icon {
    position: absolute;
    right: 0.65rem;
  }
}
