.sidebar-container {
  display: flex;
}

.tagLine-nav {
  font-size: 0.6rem;
  color: #dadada;
  text-align: center;
  margin-bottom: 40px;
}

.sidenavList {
  padding-left: 5%;
  padding-right: 5%;
}

.sidenav-programs {
  width: 50%;
  margin: 0 auto;
}

.sidenavHome {
  margin-top: 15%;
  vertical-align: middle;
}

.link:hover {
  color: #3883e6;
}

.activeLink {
  color: #3883e6 !important;
}

.sidenavTalukas {
  margin-top: 10%;
  font-size: 0.8rem;
  color: #666666;
}

.sidenavTalukasList {
  margin-top: 10%;
  font-size: 0.8rem;
}

.homeIcon {
  position: relative;
  color: #656766;
  font-size: 1rem;
  top: 1.5px;
}

.triangleIcon {
  position: relative;
  float: right;
  font-size: 0.7rem;
  padding-right: 5%;
  top: 3.5px;
}

.triangleIcon:hover {
  color: #3883e6;
  cursor: pointer;
}

.link {
  display: inline-block;
  padding: 0 10px;
  text-decoration: none;
  color: #9a9a9a;
}

.issue {
  margin-top: 50%;
  font-size: 0.8rem;
  padding-left: 13%;
}

.issueIcon {
  position: relative;
  color: #656766;
  font-size: 0.9rem;
}

.companyLogoSide {
  width: 60%;
  margin-left: 20%;
  margin-bottom: 2%;
  margin-top: 40px;
}

@media screen and (max-width: 1250px) {
  .tagLine-nav {
    font-size: 0.52rem;
  }
  .sidenav {
    width: 17%;
  }
  .sidenav-programs {
    font-size: 0.75rem;
  }
  .sidenavHome .link {
    font-size: 0.75rem;
  }
  .sidenavHome .homeIcon {
    font-size: 0.75rem;
  }
  .sidenavTalukas {
    font-size: 0.7rem;
  }

  .sidenavTaluka .triangleIcon {
    font-size: 0.5rem;
  }

  .issue .issueIcon {
    font-size: 0.65rem;
  }
  .issue .link {
    font-size: 0.65rem;
  }
}

/* Adding breakpoints for tablets view  */

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 768px) {
  .sidebar-container {
    -webkit-tap-highlight-color: transparent;
  }

  #closeIcon {
    filter: invert(1);
    scale: 1.3;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
  }
}

/* open/close animations  */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
