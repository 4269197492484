.talukaPageTalukaHead {
  margin-top: 2%;
  margin-bottom: 1%;
  font-weight: 600;
}

.villageTalukaStats {
  position: relative;
  height: 330px;
  margin-top: 1%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.noVillage {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20%;
}

.issueIcon {
  margin-right: 4%;
}

/* // MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .talukaPageTalukaHead {
    margin-top: 0%;
    font-size: 0.8rem;
  }
  .villageTalukaStats {
    height: 260px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .talukaPageTalukaHead {
    margin-top: 1%;
    font-size: 0.9rem;
  }

  .villageTalukaStats {
    height: 290px;
  }
}

@media screen and (min-width: 1700px) {
  .talukaPageTalukaHead {
    font-size: 1.1rem;
    margin-top: 3%;
  }
  .villageTalukaStats {
    height: 350px;
  }
}

@media screen and (min-width: 1900px) {
  .talukaPageTalukaHead {
    font-size: 1.2rem;
    margin-top: 4%;
  }

  .villageTalukaStats {
    height: 410px;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .villageTalukaStats {
    height: auto;
  }

  .talukaPageTalukaHead {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    margin-bottom: 0.5rem;
  }
}
