.header {
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 26px;
}

.content {
  background-color: #fff3d3;
  border-radius: 15px;
  border: 1px solid #ffe8a7;
  padding: 10px;
  margin: 7px;
}

.title {
  font-size: 11px;
  margin-left: 10px;
}

.villageSection {
  margin-top: 5%;
}

.chartContainer {
  height: 19vh;
}

.chartIndex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-top: 5px;
}

.indexItem {
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-size: 10px;
}

.indexColor {
  width: 7px;
  height: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.villagePondColor {
  background-color: #efd07f;
}

.farmPondColor {
  background-color: #ffd86e;
}

.percolationTankColor {
  background-color: #c8b277;
}

.checkDamColor {
  background-color: #f0be39;
}

.divider {
  margin: 20px 10px;
  border: 0.5px solid #ffdc68;
}

.rechargeContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}

.rechargeLabel {
  font-size: 12px;
  display: flex;
  align-items: center;
  width: 65%;
}

.rechargeIcon {
  height: 22px;
  width: 22px;
  margin-right: 10px;
}

.rechargeValue {
  font-weight: 700;
  width: 35%;
  text-align: right;
}
