.plan-dropdown .material-select {
  border: none;
}

.plan-dropdown .material-select {
  color: #3883e6;
}

.analyticsHeader {
  display: flex;
  justify-content: space-between;
  width: 84.5%;
}

.analyticsNavbar {
  display: flex;
  justify-content: space-between;
  width: 39%;
}

.analyticsNavbar div {
  font-weight: 600;
  padding: 3px 0;
}

.analyticsNavbar div .on {
  color: #3883e6;
  border-bottom: 3px solid #3883e6;
  text-decoration: none;
}

.analyticsNavbar div .off {
  text-decoration: none;
  color: #000;
}

.analyticsNavbar .inactive {
  color: #a4a4a4;
}

.analyticsDownload {
  width: 10%;
}

/* RESET AND SAVE BUTTONS STYLING */

.reset-and-save-buttons-wrapper {
  display: flex;
  position: absolute;
  right: 0;
}

.reset-and-save-buttons-wrapper .resetsave button {
  padding: 5px 38px;
  color: #ffffff;
  border: 1px solid rgba(53, 125, 220, 1);
  background: rgba(53, 125, 220, 1);
  outline: none;
  border-radius: 11px;
  cursor: pointer;
  box-shadow: 0px 5px 7px #00000029;
}

.reset-and-save-buttons-wrapper .resetsave button:hover {
  opacity: 0.9;
  transition: 0.3s all;
}

.reset-and-save-buttons-wrapper .reset-button {
  margin-right: 10px;
}

.advisoryPublishedBy {
  position: absolute;
  right: 20px;
  display: flex;
  cursor: pointer;
}

.tooltipIcon {
  height: 30px;
  width: 30px;
}

/* RESET AND SAVE BUTTONS STYLING */
@media screen and (max-width: 950px) {
  .analyticsNavbar {
    width: 48%;
  }
}

@media screen and (max-width: 1250px) {
  .reset-and-save-buttons-wrapper .resetsave button {
    font-size: 12px;
    padding: 4px 28px;
  }

  .reset-and-save-buttons-wrapper .reset-button {
    margin-right: 10px;
  }

  .analyticsNavbar {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1250px) {
  .analyticsNavbar {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1700px) {
  .analyticsNavbar {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1900px) {
  .analyticsNavbar {
    font-size: 1.2rem;
  }
}

/* Mobile screens  */

@media only screen and (max-width: 768px) {
  .pageNavParentDiv {
    margin-bottom: 16px;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .analyticsHeader {
    width: 72%;
  }

  .analyticsNavbar {
    width: 95%;
  }

  .analyticsDownload {
    width: 20%;
  }
  .pageNavParentDiv {
    margin-bottom: 16px;
    flex-direction: column;
  }

  .reset-and-save-buttons-wrapper {
    /* display: flex; */
    position: relative;
    justify-content: flex-end;
  }

  .reset-and-save-buttons-wrapper .resetsave button {
    padding: 4px 22px;
    font-size: 0.65rem;
  }

  .analyticsFullWidthMobile {
    width: 100%;
  }

  .advisoryPublishedBy {
    right: 7px;
    display: flex;
    cursor: pointer;
  }

  .tooltipIcon {
    cursor: unset;
    height: 25px;
    width: 25px;
  }
}
