.alertGroundwaterHead {
  margin-top: 0.9vw;
  padding: 0.7vw;
  background-color: #f6f6f6;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 0.9rem;
  cursor: pointer;

  /* display: flex;
  align-items: center; */
}

.alertGroundwaterHeadContentValue {
  font-weight: 600;
  font-size: 0.9rem;
  margin-left: 2%;
}

.alertGroundwaterHeadContentIcon {
  float: right;
  margin-top: 0.8%;
}

.alertGroundwaterHeadContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.alertHeadImageWrapper{
  width: 100%;
  display: flex;
  align-items: center;
}

/* MEDIA QUERIES STARTS HERE */

@media screen and (max-width: 1250px) {
  .alertGroundwaterHeadContent {
    font-size: 0.7rem;
  }

  .alertGroundwaterHeadContentValue {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1250px) {
  .alertGroundwaterHeadContent {
    font-size: 0.8rem;
  }

  .alertGroundwaterHeadContentValue {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1700px) {
  .alertGroundwaterHeadContent {
    font-size: 1rem;
  }

  .alertGroundwaterHeadContentValue {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1900px) {
  .alertGroundwaterHeadContent {
    font-size: 1.1rem;
  }

  .alertGroundwaterHeadContentValue {
    font-size: 1rem;
  }
}

/* Adding breakpoints for tabs view  */
@media only screen and (max-width: 768px) {
  .alertGroundwaterHead {
    padding: 1.5vw;
    margin-top: 2vw;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .alertGroundwaterHead {
    padding: 1.7vw;
    margin-top: 2vw;
  }
}