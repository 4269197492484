.surfaceAlertContent {
  margin-top: 3%;
  display: none;
}

.surfaceAlertContentKey {
  margin-left: 2%;
  font-size: 0.9rem;
  margin-bottom: 0.5%;
  display: flex;
  align-items: center;
}

.surfaceAlertContentValue {
  font-weight: 600;
  margin-left: 5%;
}

.surfacewaterlevel {
  font-size: 0.7rem;
  margin-top: -25px;
  max-width: 145px;
  font-weight: normal;
}

.surfacewaterbodies {
  font-size: 0.8rem !important;
}

.surfacewaterbodiessum {
  font-size: 0.9rem !important;
}

.surfaceAlertStatus {
  display: flex;
  margin-top: 1.5%;
}

.surfaceAlertStatus .left {
  margin-top: 5%;
  width: 35%;
  margin-left: 5%;
  font-size: 0.75rem;
  font-weight: 600;
}

.surfaceAlertStatus .left span {
  font-size: 1.2rem;
  color: #3883e6;
}

.surfaceAlertButton {
  display: inline-block;
  text-align: center;
  background-color: #3883e6;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 0.7rem;
  box-shadow: 0px 5px 7px #00000029;
  text-decoration: none;
}

.surfaceAlertButton {
  cursor: pointer;
  font-weight: 600;
}

.surfaceAlertButton:hover {
  background-color: #073f89;
}

.surfaceAlertStatus .right {
  width: 64%;
  height: 190px;
  margin-top: -25px;
}

.surfaceAlertStatusGraph {
  margin-top: 2%;
}

.surfacewaterbodiesParentDiv {
  margin-top: 15px;
  font-size: 13px;
  max-width: 160px;
  font-weight: normal;
}

.surfaceAlertButtonMobile {
  display: none;
}

.totalSurfaceWaterHeading {
  margin-left: 18px;
}

.surfaceAlertButtonMobileDiv {
  display: none;
}

/* ADDING NEW STYLES  */
.surfaceAlertDotStyle {
  background: #000000;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-right: 6px;
  position: absolute;
}

/* MEDIA QUERIES STARTS FROM HERE */
@media screen and (max-width: 1350px) {
}

@media screen and (max-width: 1250px) {
  .surfaceAlertContentKey {
    font-size: 0.7rem;
  }

  .surfaceAlertContentValue {
    font-size: 0.8rem;
  }

  .surfacewaterbodies {
    font-size: 0.7rem !important;
  }

  .surfacewaterbodiessum {
    font-size: 0.8rem !important;
  }

  .surfacewaterlevel {
    font-size: 0.6rem;
    margin-top: -13px;
  }

  .surfaceAlertStatus .left,
  .surfaceAlertStatus .right {
    font-size: 0.65rem;
  }
  .surfaceAlertStatus .right {
    height: 160px;
  }

  .surfaceAlertButton {
    font-size: 0.6rem;
  }

  .surfaceAlertStatus .left,
  .surfaceAlertStatus .right {
    font-size: 0.65rem;
  }
  .surfaceAlertStatus .right {
    height: 160px;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1250px) {
  .surfaceAlertContentKey {
    font-size: 0.8rem;
  }

  .surfaceAlertContentValue {
    font-size: 0.9rem;
  }

  .surfaceAlertStatus .left,
  .surfaceAlertStatus .right {
    font-size: 0.7rem;
  }
  .surfaceAlertStatus .right {
    height: 170px;
  }

  .surfaceAlertButton {
    font-size: 0.65rem;
  }
}

@media screen and (min-width: 1700px) {
  .surfaceAlertContentKey {
    font-size: 1rem;
  }

  .surfaceAlertContentValue {
    font-size: 1.1rem;
  }

  .surfaceAlertStatus .left,
  .surfaceAlertStatus .right {
    font-size: 0.825rem;
  }
  .surfaceAlertStatus .right {
    height: 200px;
  }

  .surfaceAlertButton {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1900px) {
  .surfaceAlertContentKey {
    font-size: 1.1rem;
  }

  .surfaceAlertContentValue {
    font-size: 1.2rem;
  }

  .surfaceAlertStatus .left,
  .surfaceAlertStatus .right {
    font-size: 1rem;
  }
  .surfaceAlertStatus .right {
    height: 210px;
  }

  .surfaceAlertButton {
    font-size: 0.9rem;
  }
}

/* // mobile screens  */

@media screen and (max-width: 600px) {
  .surfaceAlertStatus {
    flex-direction: column-reverse;
  }

  .surfaceAlertStatus .right {
    width: 100%;
    margin-top: 0px;
  }
  .surfaceAlertStatus .left {
    width: 100%;
    margin-top: 10px;
    display: flex;
  }

  .surfacewaterlevel {
    margin-top: unset;
  }

  .surfaceAlertButton {
    display: none;
  }

  .surfacewaterbodiesParentDiv {
    margin-top: unset;
    margin-left: 10px;
  }

  .surfacewaterbodiessum {
    font-size: 0.7rem !important;
  }

  .surfaceAlertButtonMobileDiv {
    display: block;
    padding: 0 16px;
  }

  .surfaceAlertButtonMobile {
    display: block;
    display: inline-block;
    text-align: center;
    background-color: #3883e6;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 5px 20px;
    font-size: 0.7rem;
    box-shadow: 0px 5px 7px #00000029;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
  }

  .surfaceAlertStatus .right {
    height: 175px;
    margin-left: -10px;
  }

  .totalSurfaceWaterHeading {
    margin-left: 15px;
  }

  .surfaceAlertContent {
    margin-top: unset;
    padding: 15px 0;
  }

  .surfaceAlertContentValue {
    margin-left: 21px;
  }
}
