/* .common-input {
  Firefox
} */
.common-input ::-webkit-outer-spin-button,
.common-input ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table-upper-area input[type='number']::-webkit-inner-spin-button,
.table-upper-area input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* .common-input [type='number'] {
  -moz-appearance: textfield;
} */
.main-crop-heading {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}
.cultivation-container .total-cultivation-area {
  margin-top: 44px;
  margin-left: 14px;
}

.cultivation-container .total-drip-area {
  margin-top: 44px;
}

.crop-slightly-left-portion {
  margin-left: 10px;
}
.crop-area-reduced-section {
  margin-top: 19px;
  display: flex;
  margin-bottom: 45px;
  justify-content: space-between;
  width: 82%;
}
.crop-table-heading {
  margin-bottom: 20px;
}
.crop-table-content {
  margin-left: 14px;
  width: 93%;
}
.crop-upper-content {
  display: flex;
  align-items: center;
}
.crop-upper-content .table-upper-crop {
  width: 32%;
}
.crop-upper-content div:nth-of-type(2) {
  width: 28%;
  text-align: center;
}
.crop-upper-content div:nth-of-type(3) {
  width: 40%;
  text-align: right;
}
.main-table-content {
  margin-top: 18px;
}
.main-table-content-headings {
  display: flex;
  background-color: rgba(56, 131, 230, 0.33);
  padding: 2px 7px;
  align-items: center;
  height: 35px;
}
.main-table-content-headings div:nth-of-type(1) {
  width: 26%;
}
.main-table-content-headings div:nth-of-type(2) {
  width: 26%;
  text-align: center;
}
.main-table-content-headings .thirdDivCd {
  width: 24%;
}
.main-table-content-details {
  width: 71%;
  border-right: 1px solid black;
  height: 35px;
  display: flex;
  padding: 2px 7px;
  align-items: center;
}

.main-table-content-details div:nth-of-type(1) {
  width: 38%;
}
.main-table-content-details div:nth-of-type(2) {
  width: 30%;
  text-align: center;
  display: flex;
  justify-content: center;
}
.main-table-content-details div:nth-of-type(3) {
  width: 32%;
  text-align: right;
}
.main-table-content-details span {
  cursor: pointer;
}
.main-table-content-details .minus,
.main-table-content-details .plus {
  width: 13px;
  height: 13px;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 5px 5px 5px 5px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin: 0 2px;
  cursor: pointer;
}
.main-table-content-details .minus :hover,
.main-table-content-details .plus :hover {
  transition: all 1s;
}
.main-table-content-details .common-input {
  height: 21px;
  width: 49px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.main-table-content-details .common-input ::-webkit-outer-spin-button,
.main-table-content-details .common-input ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* .main-table-content-details .common-input [type='number'] {
  -moz-appearance: textfield;
} */
.main-table-content-details .table-upper-buttons button {
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 5px 7px #00000029;
}
.main-table-content-details .table-upper-buttons button:nth-of-type(2) {
  background: #d83636;
  color: #ffffff;
  margin-left: 10px;
}
.main-table-content-details .isEditing button:nth-of-type(1) {
  background: #3883e6;
  color: #ffffff;
}
.main-table-content-details .isEditing button:nth-of-type(2) {
  background: #fff;
  color: black;
}
.table-upper-buttons button {
  cursor: pointer;
  border: none;
  background: white 0% 0% no-repeat;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 5px 4px;
  width: 85px;
}
.table-upper-buttons button:nth-of-type(1) {
  background: white;
  margin-right: 5px;
}
.minus,
.plus {
  width: 16px;
  height: 16px;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 5px 5px 5px 5px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin: 0 2px;
}
.common-input {
  height: 24px;
  width: 50px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.ButtonParent {
  display: flex;
}

.ButtonParent .ed-up-ca-del-buttons {
  padding: 2px;
}

/* New styling added  */
.commonMuiButton {
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.cropDivAreasDiv {
  display: flex;
  justify-content: space-between;
  width: 56.8%;
}

.mobileUpdateButtons {
  display: none;
}

.addButtonCropDiv {
  width: 70px;
}

.mobileLastRowTable {
  width: 25%;
}

.addBrCropDiv {
  display: none;
}

.cultivation-container {
  width: 86%;
}

.crop-diversification-header {
  margin-bottom: 20px;
}

.shortCropNames {
  font-size: 11px;
}

.errorExceedCultivation {
  top: 90px;
}

.justifyMi{
  justify-content: unset;
}

/* ${'' STARTING MEDIA QUERIES FROM HERE } */
@media screen and (max-width: 1300px) {
  /* ${'' WILL SEE BUTTONS LATER } */
  .main-crop-heading {
    font-size: 16px;
  }
  .table-upper-crop p {
    font-size: 14px;
  }
  .table-upper-area p {
    font-size: 14px;
  }
  .main-table-content-headings p {
    font-size: 14px;
  }
  .main-table-content-details p {
    font-size: 13px;
  }
  .cultivation-container p {
    font-size: 14px;
  }
  .crop-area-reduced-section p {
    font-size: 14px;
  }
  .ButtonParent .ed-up-ca-del-buttons {
    padding: 1px;
    font-size: 12px;
  }
}

/* FOR MOBILE SCREENS  */

@media screen and (max-width: 600px) {

  .justifyMi{
    justify-content: center;
    width: unset!important;
  }

  .main-crop-heading {
    font-size: 0.9rem;
  }

  .table-upper-crop p {
    font-size: 0.75rem;
  }

  .cropDivSeasonHeading {
    font-size: 0.75rem;
  }

  .crop-upper-content {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }

  .cropDivAreasDiv {
    display: flex;
    justify-content: unset;
    align-items: flex-end;

    width: 100%;
  }

  .table-upper-area-one {
    margin-right: 25px;
  }

  .table-upper-area-one p {
    font-size: 0.75rem;
  }

  .table-upper-area p {
    font-size: 0.75rem;
  }

  /* table styling */

  .main-table-content-headings p {
    font-size: 0.7rem;
  }

  .main-table-content-details p {
    font-size: 0.65rem;
  }

  .main-table-content-headings div:nth-of-type(1) {
    width: 32%;
  }
  .main-table-content-headings div:nth-of-type(2) {
    width: 32%;
    text-align: center;
  }
  .main-table-content-headings .thirdDivCd {
    width: 33%;
    text-align: center;
  }

  .main-table-content-details {
    width: 100%;
    border-right: unset;
    min-height: 35px;
    height: auto;
  }

  .main-table-content-details div:nth-of-type(1) {
    width: 32%;
  }
  .main-table-content-details div:nth-of-type(2) {
    width: 32%;
  }
  .main-table-content-details div:nth-of-type(3) {
    width: 33%;
  }

  .crop-table-content {
    margin-left: unset;
    width: 100%;
  }

  .ButtonParent {
    display: none;
  }

  .mobileUpdateButtons {
    margin-top: 10px;
    justify-content: flex-end;
  }

  .mobileUpdateButtons,
  .mobileUpdateButtons .ButtonParent {
    display: flex;
  }

  .addButtonCropDiv {
    width: 70px;
    height: 25px;
    font-size: 0.6rem;
  }

  .cultivation-container p {
    font-size: 0.75rem;
  }
  .crop-area-reduced-section p {
    font-size: 0.75rem;
  }

  .mobileLastRowTable {
    width: 33%;
  }

  .main-table-content-details .common-input {
    font-size: 0.6rem;
    width: 35px;
  }

  .common-input {
    font-size: 0.6rem;
  }

  .crop-area-reduced-section .newCropsAddedLastDiv {
    margin-left: 40px;
  }

  .cultivation-container {
    width: 90%;
  }

  .addBrCropDiv {
    display: block;
  }

  .crop-area-reduced-section {
    width: 90%;
  }

  .cultivation-container .total-cultivation-area {
    margin-top: 30px;
  }

  .cultivation-container .total-drip-area {
    margin-top: 30px;
  }

  .crop-diversification-header {
    margin-bottom: 10px;
  }
  .shortCropNames {
    font-size: 0.6rem;
  }

  .globalErrorUpdate {
    top: -40px;
  }

  .errorExceedCultivation {
    top: -65px;
  }

  .cultivationExceedAdd,
  .cultivationExceedDripAdd {
    font-size: 0.6rem;
  }
}
