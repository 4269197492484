.noAdvisoryContainer {
  position: relative;
  height: 100%;
}

.noAdvisoryBox {
  margin-top: 20px;
  min-width: 300px;
  padding: 40px 70px;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, 30%);
  background: #ffffff;
  box-shadow: 0px 6px 5px 0px #00000040;
}

@media screen and (max-width: 768px) {
  .noAdvisoryBox {
    margin-top: 20px;
    min-width: 200px;
    padding: 50px;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 30%);
    background: #ffffff;
    box-shadow: 0px 6px 5px 0px #00000040;
  }

  .noAdvisoryBox h3 {
    font-size: 1rem;
  }
}
