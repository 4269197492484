.program-stats-container {
  height: 150px;
  padding: 15px;
}

.area-under-cultivation {
  margin-bottom: 0px;
}

.homeProgramStatsBoxHead {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 20px;
}
.homeProgramStatsBoxHead span {
  font-size: 1.6rem;
}
.noStats {
  position: relative;
  left: 50%;
  top: 35%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.issueIcon {
  margin-right: 4%;
}
.homeProgramStatsBoxContent {
  text-align: center;
}
.homeProgramPieBoxContent {
  display: flex;
  justify-content: space-between;
  height: 90%;
}
.homeDripGraphPie {
  width: 50%;
  height: 100%;
}
.homeDripImplement {
  position: relative;
  font-size: 0.65rem;
  width: 50%;
  font-weight: 500;
}
.homeDripGraph {
  width: 50%;
  height: 100%;
}
.homeDripImplement .value {
  font-size: 1.1rem;
  font-weight: 600;
}
.homeDripImplement .unit {
  font-size: 0.8rem;
  font-weight: 600;
}

/* New classes added  */
.homeProgramStatsBoxHead .cropDripArea {
  margin-top: 10px;
}

@media screen and (max-width: 1250px) {
  .program-stats-container {
    height: 100px;
  }
  .homeProgramStatsBoxHead {
    font-size: 0.65rem;
  }
  .homeProgramStatsBoxHead span {
    font-size: 0.8rem;
  }
  .homeDripImplement {
    font-size: 0.5rem;
    width: 58%;
  }
  .homeDripGraph {
    width: 42%;
    height: 100%;
  }
  .homeDripImplement .value {
    font-size: 0.8rem;
  }
  .homeDripImplement .unit {
    font-size: 0.55rem;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .homeProgramStatsBoxHead {
    font-size: 0.75rem;
  }
  .homeDripImplement {
    font-size: 0.65rem;
  }
  .homeDripImplement .value {
    font-size: 0.9rem;
  }
  .homeDripImplement .unit {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 1700px) {
  .homeProgramStatsBoxHead {
    font-size: 0.9rem;
  }
  .homeDripImplement {
    font-size: 0.75rem;
  }
  .homeDripImplement .value {
    font-size: 1.1rem;
  }
  .homeDripImplement .unit {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 1900px) {
  .homeProgramStatsBoxHead {
    font-size: 1rem;
  }
  .homeDripImplement {
    font-size: 0.8rem;
  }
  .homeDripImplement .value {
    font-size: 1.2rem;
  }
  .homeDripImplement .unit {
    font-size: 0.9rem;
  }
}

/* Adding breakpoints for mobile view  */
/* @media only screen and (max-width: 480px) { */
@media only screen and (max-width: 600px) {
  .program-stats-container {
    height: 5rem;
    padding: 9px;
  }

  .homeProgramStatsBoxHead {
    font-size: 0.65rem;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .area-under-cultivation {
    font-size: 0.5rem;
    font-weight: 300;
    margin-bottom: 3px;
    letter-spacing: 0.0125rem;
  }

  .homeProgramStatsBoxHead .cropDripArea {
    margin-top: 7px;
    color: #3883e6;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .homeDripImplement {
    width: 68%;
  }

  .homeDripGraph {
    width: 32%;
    height: 100%;
  }

  .homeDripImplement .value {
    font-size: 0.75rem;
  }

  .homeDripImplement .unit {
    font-size: 0.75rem;
  }
}

/* FOR SMALLER MOBILE SCREEN  */
@media only screen and (max-width: 320px) {
  .homeProgramStatsBoxHead {
    font-size: 0.6rem;
    margin-bottom: 9px;
  }

  .area-under-cultivation {
    font-size: 0.45rem;
    margin-bottom: 3px;
  }

  .homeProgramStatsBoxHead .cropDripArea {
    margin-top: 2px;
    color: #3883e6;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .homeDripImplement .value {
    font-size: 0.7rem;
  }

  .homeDripImplement .unit {
    font-size: 0.7rem;
  }
}
