.filterParentDiv {
  display: flex;
  padding: 0 8px;
}

.scenariosTab {
  margin-top: 80px;
  color: #448ae7;
  font-style: italic;
}

.scenario-names {
  margin-left: 24px;
}

.right-side-filter-modal {
  margin-left: 20px;
}

/* MOBILE SCREENS  */

@media screen and (max-width: 768px) {
  .filterParentDiv {
    flex-direction: column;
  }

  .scenariosTab {
    margin-top: 15px;
    font-size: 0.75rem;
  }

  .scenario-names {
    margin-left: 0;
  }

  .left-side-filter-modal {
    font-size: 0.85rem;
  }

  .right-side-filter-modal {
    margin-top: 20px;
    margin-left: 0;
    font-size: 0.85rem;
  }

  .paginationFilter {
    display: flex;
    justify-content: center;
  }
}
