  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .errorButton {
    text-decoration: none;
    background-color: #3884e6; 
    border: none;
    border-radius: 10px;
    color: white;
    padding: 12px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin:0 auto;
    display:block;
  }

  .errorButton:hover {
    background-color: #3068f4; 
  }