.activeLink {
  color: #3883e6 !important;
}

.sidenavTalukaList {
  color: #9a9a9a;
  margin-bottom: 5%;
  padding: 8px;
}

.userIcon {
  position: relative;
  color: #656766;
  font-size: 0.8rem;
  top: 1.5px;
}

.link {
  display: inline-block;
  padding: 0 10px;
  text-decoration: none;
  color: #9a9a9a;
}

@media screen and (max-width: 1250px) {
   .userIcon {
    font-size: 0.65rem;
  }
   .link {
    font-size: 0.7rem;
  }
}
