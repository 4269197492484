.surfacewaterContentMapWrapper {
  background-color: #fff;
  margin-right: 1.5%;
  width: 50%;
  height: 540px;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
}

.surfacewaterContentLeftHead {
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 3%;
  margin-left: 5%;
}

.surfacewaterContentLeftMap {
  margin-top: 2%;
  width: 95%;
  height: 85%;
  padding: 1% 2.5%;
}

.surfacewaterContentLeftMapMap {
  width: 100%;
  height: 460px;
}

/* // MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .surfacewaterContentMapWrapper {
    height: 440px;
  }

  .surfacewaterContentLeftHead {
    font-size: 0.75rem;
  }

  .surfacewaterContentLeftMapMap {
    height: 385px;
  }
}

@media screen and (max-width: 950px) {
  .surfacewaterContentMapWrapper {
    width: 100%;
    margin-bottom: 15px;
  }

  .surfacewaterContentLeftMap {
    margin-top: unset;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .surfacewaterContentMapWrapper {
    height: 510px;
  }

  .surfacewaterContentLeftHead {
    font-size: 0.85rem;
  }

  .surfacewaterContentLeftMapMap {
    height: 440px;
  }
}

@media screen and (min-width: 1700px) {
  .surfacewaterContentMapWrapper {
    height: 600px;
  }

  .surfacewaterContentLeftHead {
    font-size: 1rem;
  }

  .surfacewaterContentLeftMapMap {
    height: 520px;
  }
}

@media screen and (min-width: 1900px) {
  .surfacewaterContentMapWrapper {
    height: 677px;
  }

  .surfacewaterContentLeftHead {
    font-size: 1.15rem;
  }
  .surfacewaterContentLeftMapMap {
    height: 585px;
  }
}

/* // tabs and mobile screens  */

@media screen and (max-width: 768px) {
  .surfacewaterContentMapWrapper {
    height: 370px;
  }

  .surfacewaterContentLeftMapMap {
    height: 298px;
  }
}
@media screen and (max-width: 600px) {
  .surfacewaterContentMapWrapper {
    height: 345px;
  }
}
