.groundwater {
  position: relative;
  margin-top: 1.5vw;
  margin-left: 18%;
  margin-right: 40px;
}

.groundwaterNavbar {
  display: flex;
  justify-content: space-between;
}

.groundwaterNavbarBack {
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.groundwaterNavbarBack:hover {
  color: #3884e6;
}

.groundwaterContent {
  display: flex;
  margin-top: 2%;
}

.groundwaterContentRight {
  width: 50%;
  display: grid;
}

.groundwaterContentRightTop {
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 12px;
  padding: 10px 20px;
  box-shadow: 0px 3px 6px #00000029;
}

.groundwaterContentRightTopHeadKey {
  font-size: 0.85rem;
}

.groundwaterContentRightTopHeadValue1 {
  font-size: 1.1rem;
  font-weight: 600;
}

.groundwaterContentRightTopBottom {
  margin-top: 0.6%;
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
}

.groundwaterPieChart {
  width: 60%;
  height: 100%;
}

.groundwaterContentLegend {
  width: 50%;
  font-size: 0.8rem;
}

.groundwaterContentLegendTop {
  display: flex;
}

.left1 {
  height: 10px;
  width: 10px;
  background-color: #85665b;
  border-radius: 50%;
  display: inline-block;
  margin-top: 2.5%;
}

.middle1 {
  margin-left: 5%;
  margin-right: 5%;
}

.right1 {
  font-weight: 600;
}

.groundwaterContentLegendBottom {
  display: flex;
  margin-top: 6%;
}

.left2 {
  height: 10px;
  width: 10px;
  background-color: #5776a2;
  border-radius: 50%;
  display: inline-block;
  margin-top: 2.5%;
}

.middle2 {
  margin-left: 5%;
  margin-right: 5%;
}

.right2 {
  font-weight: 600;
}

.groundwaterContentRightBottom {
  background-color: #fff;
  border-radius: 12px;
  padding: 10px 20px;
  box-shadow: 0px 3px 6px #00000029;
}

/* // MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .groundwater {
    margin-left: 18.5%;
  }

  .groundwaterNavbarBack {
    font-size: 0.75rem;
  }

  .groundwaterContent {
    margin-top: 1%;
  }

  .groundwaterContentRightTopHeadKey {
    font-size: 0.68rem;
  }

  .groundwaterContentRightTopHeadValue1 {
    font-size: 0.8rem;
  }

  .groundwaterContentRightTopBottom {
    height: 140px;
  }

  .groundwaterContentLegend {
    font-size: 0.65rem;
  }

  .groundwaterContentRightBottom {
    height: 217px;
  }
}

@media screen and (max-width: 950px) {
  .groundwater {
    margin-left: 23%;
    margin-right: 3%;
  }

  .groundwaterContent {
    flex-direction: column;
  }

  .groundwaterContentRight {
    width: 100%;
  }

  .groundwaterContentRightTop {
    height: 175px;
  }

  .groundwaterContentRightBottom {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .groundwaterNavbarBack {
    font-size: 0.85rem;
  }

  .groundwaterContent {
    margin-top: 1%;
  }

  .groundwaterContentRightTop {
    height: 200px;
  }

  .groundwaterContentRightTopHeadKey {
    font-size: 0.75rem;
  }

  .groundwaterContentRightTopHeadValue1 {
    font-size: 0.9rem;
  }

  .groundwaterContentRightTopBottom {
    height: 145px;
  }

  .groundwaterContentLegend {
    font-size: 0.7rem;
    right: 6.5%;
  }

  .groundwaterContentRightBottom {
    margin-top: 10px;
    height: 230px;
  }
}

@media screen and (min-width: 1700px) {
  .groundwaterNavbarBack {
    font-size: 1.1rem;
  }

  .groundwaterContent {
    margin-top: 2%;
  }

  .groundwaterContentRightTop {
    height: 230px;
  }

  .groundwaterContentRightTopHeadKey {
    font-size: 0.9rem;
  }

  .groundwaterContentRightTopHeadValue1 {
    font-size: 1.2rem;
  }

  .groundwaterContentRightTopBottom {
    height: 175px;
  }

  .groundwaterContentLegend {
    font-size: 0.9rem;
  }

  .groundwaterContentRightBottom {
    height: 315px;
  }
}

@media screen and (min-width: 1900px) {
  .groundwaterNavbarBack {
    font-size: 1.25rem;
  }

  .groundwaterContent {
    margin-top: 2%;
  }

  .groundwaterContentRightTop {
    height: 260px;
  }

  .groundwaterContentRightTopHeadKey {
    font-size: 1rem;
  }

  .groundwaterContentRightTopHeadValue1 {
    font-size: 1.3rem;
  }

  .groundwaterContentRightTopBottom {
    height: 190px;
  }

  .groundwaterContentLegend {
    font-size: 1rem;
  }

  .groundwaterContentRightBottom {
    height: 360px;
  }
}

/* Adding breakpoints for tablets view  */
@media only screen and (max-width: 768px) {
  .groundwater {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;
  }

  .groundwaterPieChart {
    width: 45%;
  }

  .groundwaterContentLegend {
    width: 55%;
  }

  .groundwaterNavbarBack {
    margin: 7px 0px;
  }

  .groundwaterContentRightBottom {
    height: auto;
    margin-bottom: 15px;
  }
}
