.header {
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 26px;
}

.content {
  background-color: #f5f5f5;
  border-radius: 15px;
  border: 1px solid #bbbbbb;
  padding: 2px;
  margin: 7px;
}

.index {
  border-radius: 15px;
  border: 0.5px dashed #3f3f3f;
  padding: 20px;
}

.label {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.color {
  width: 25%;
  height: 3.5vh;
  border: 1px solid #535353;
  border-radius: 3px;
}

.text {
  width: 65%;
  font-size: 12px;
}
