.scheduledPlanningMonth {
  position: absolute;
  right: 15%;
  top: 64%;
  color: rgb(56, 131, 230);
  font-size: 13.5px;
}

@media screen and (max-width: 1250px) {
  .scheduledPlanningMonth {
    right: 18%;
  }
}
@media screen and (max-width: 850px) {
  .scheduledPlanningMonth {
    right: 24%;
    font-size: 12px;
  }
}

/* @media screen and (max-width: 768px) {
  .scheduledPlanningMonth {
    right: 44%;
  }
} */

@media screen and (max-width: 600px) {
  .scheduledPlanningMonth {
    right: 56%;
    /* right:30%; */
  }
}
@media screen and (max-width: 410px) {
  .scheduledPlanningMonth {
    top: 66%;
    /* right: 34%; */
    right:60%;
    font-size: 10.5px;
  }
}
