.savedScenarioCropDiversification-wrapper {
  position: relative;
  margin-top: 1.5vw;
  margin-left: 18%;
  margin-right: 40px;
}

.new-crops-here {
  font-size: 14px;
}
@media screen and (max-width: 950px) {
  .savedScenarioCropDiversification-wrapper {
    margin-left: 23%;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 1em;
  justify-items: stretch;
  align-items: stretch;
}
.item {
  background: #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 12px;
}
.item1 {
  grid-row: 1/3;
  padding: unset;
}
.item2 {
  grid-row: 3/5;
  font-size: 19px;
}
.item3 {
  grid-row: 5/6;
  font-size: 19px;
}
.item4 {
  font-size: 18px;
}
.item4,
.item5 {
  grid-row: 1/6;
}
.third-item-three .third-less-padding {
  display: flex;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  padding: 0px 0;
  width: '100%';
}
.third-item-three .less-padding-top-right {
  display: flex;
  justify-content: space-between;
}
.third-item-three .less-padding-top-right .less-padding-right-two {
  text-align: center;
}
.third-item-three .bar-div {
  display: flex;
  margin-bottom: 30px;
}
.third-item-three .bar-div-suitebar {
  width: 40.33%;
}
.third-item-three .right-two-ones {
  display: flex;
  align-items: center;
  width: 37%;
}
.third-item-three .right-two-ones .twelve {
  width: 50%;
}
.third-item-three .right-two-ones .twelve p {
  font-weight: bold;
}
.third-item-three .right-two-ones .twelve-two {
  font-weight: bold;
  width: 50%;
  display: flex;
  justify-content: center;
}
.second-styletable :last-child td,
.second-styletable :last-child th {
  background: #f3c2c2;
}
.first-box-demand,
.first-box-demand-value,
.first-box-available,
.first-box-available-value,
.first-box-balance {
  font-size: 19px;
}
.first-box-balance-value {
  font-size: 20px;
}
.months-remaining,
.total-water-saved {
  font-size: 22px;
}

/* NEW ADDED  */
.table1CropDiv,
.table2CropDiv {
  width: 50%;
  margin-top: 80px;
}

.table1CropDiv {
  margin-right: 50px;
}

.groundwaterLastText {
  margin: 30px 0 20px 0;
}

.areaReducedText {
  margin-right: 40px;
}

/* MEDIA QUERIES STARTS FROM HERE  */
@media screen and (max-width: 1250px) {
  .first-box-demand,
  .first-box-demand-value,
  .first-box-available,
  .first-box-available-value,
  .first-box-balance {
    font-size: 17px;
  }
  .first-box-balance-value {
    font-size: 18px;
  }
  .months-remaining,
  .total-water-saved {
    font-size: 18px;
  }
  .first-box-balance-text {
    font-size: 14px;
  }
  .item2 {
    font-size: 17px;
  }
  .item3 {
    font-size: 17px;
  }
  .item4 {
    font-size: 15px;
  }
  .new-crops-here {
    font-size: 12px;
  }
}
@media screen and (max-width: 1150px) {
  .grid-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .item1,
  .item2,
  .item3 {
    grid-row: unset;
  }
  .item1 {
    height: 200px;
  }
  .item3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .item4 {
    grid-row: unset;
    grid-column: 1/-1;
  }
  .first-box-demand,
  .first-box-demand-value,
  .first-box-available,
  .first-box-available-value,
  .first-box-balance {
    font-size: 14px;
  }
  .first-box-balance-value {
    font-size: 14px;
  }
  .months-remaining,
  .total-water-saved {
    font-size: 14px;
  }
  .first-box-balance-text {
    font-size: 13px;
  }
  .item2 {
    font-size: 14px;
  }
  .item3 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1700px) {
  .grid-container {
    height: 700px;
  }
}

/* MOBILE SCREENS  */

@media screen and (max-width: 768px) {
  .savedScenarioCropDiversification-wrapper {
    position: relative;
    margin-top: 1.5vw;
    margin-left: 18px;
    margin-right: 18px;
  }

  .item1 {
    height: 175px;
  }

  .first-box-demand,
  .first-box-demand-value,
  .first-box-available,
  .first-box-available-value,
  .first-box-balance {
    font-size: 0.75rem;
  }

  .first-box-balance-text {
    font-size: 0.7rem;
  }

  .groundwaterLastText {
    margin: 10px 0 15px 0;
    font-size: 0.7rem;
  }
  .totalWaterSavedText {
    font-size: 0.7rem;
    margin: 10px 0 15px 0;
  }
}

@media screen and (max-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .item1 {
    grid-row: unset;
    grid-column: 1/4;
  }
  .item2 {
    grid-row: unset;
    grid-column: 1/2;
  }
  .item3 {
    grid-row: unset;
    grid-column: 2/4;
  }

  .item4 {
    width: unset;
  }

  .item4,
  .item5 {
    grid-row: unset;
    grid-column: 1/4;
  }

  .tablesParentDiv {
    flex-direction: column;
  }

  .table1CropDiv,
  .table2CropDiv {
    width: 100%;
    margin-top: 30px;
  }

  .table2CropDiv {
    margin-right: 50px;
  }

  .item4 {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .areaReducedText {
    margin-right: 40px;
  }
}
