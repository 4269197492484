.calendarTriangleLeftIcon {
  position: absolute;
  top: 62%;
  right: 43%;
  cursor: pointer;
}

#month {
  width: 24.01%;
  overflow: scroll;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 58.5%;
  right: 18%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: center;
  border: 2px #eaeae8 solid;
  border-radius: 5px;
}

.month-cell {
  text-align: center;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5% 0;
  border-right: 1px #eaeae8 solid;
}

.calendarTriangleRightIcon {
  position: absolute;
  top: 62%;
  right: 16%;
  cursor: pointer;
}

/* MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .calendarTriangleLeftIcon {
    top: 64%;
    right: 49%;
    font-size: 0.6rem;
  }

  #month {
    right: 24%;
  }
  .month-cell {
    font-size: 0.65rem;
  }

  .calendarTriangleRightIcon {
    top: 64%;
    right: 22.3%;
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .calendarTriangleLeftIcon {
    top: 64.5%;
    font-size: 0.7rem;
  }
  .month-cell {
    font-size: 0.8rem;
  }

  .calendarTriangleRightIcon {
    top: 64.5%;
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1700px) {
  .calendarTriangleLeftIcon {
    top: 62%;
    font-size: 1.1rem;
  }

  .month-cell {
    font-size: 0.9rem;
  }

  .calendarTriangleRightIcon {
    top: 62%;
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1900px) {
  .calendarTriangleLeftIcon {
    top: 63%;
    font-size: 1.3rem;
  }

  .month-cell {
    font-size: 1.05rem;
  }

  .calendarTriangleRightIcon {
    top: 63%;
    font-size: 1.3rem;
  }
}

/* tabs  */
@media (width <= 768px) {
  .monthMobile {
    position: absolute;
    right: 20%;
    top: 58%;
  }
  .monthMobile .monthMobileOptions {
    font-size: 0.8rem;
    width: 5rem;
    height: 1.6rem;
  }
}
/* mobile  */
@media (width <= 480px) {
  .monthMobile {
    right: 25%;
  }
}
