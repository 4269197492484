.analytics {
  position: absolute;
  right: 0.5vw;
  top: 5vh;
  width: 17.5vw;
  min-width: 260px;
  max-width: 350px;
}

.control {
  display: flex;
  justify-content: space-between;
}

.control button {
  background-color: #fff;
  color: #000;
  width: 31%;
  border: none;
  height: 5.5vh;
  border-radius: 8px;
  font-weight: 600;
  box-shadow: 0px 0px 7.4px 0px #00000012;
}

.control button:hover {
  color: #fff;
  background-color: #a1a1a1;
  cursor: pointer;
}

.spacer {
  height: 1.1vh;
}

.canvas {
  min-height: 13vh;
  max-height: 55vh;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 7.4px 0px #00000012;
  padding: 3px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.loader {
  margin-top: 5vh;
  text-align: center;
}
