.heading-area-metdata {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 18px;
  font-size: 0.75rem;
}

.chart-wrapper {
  height: 205px;
}

.chartFont {
  font-size: 0.65rem;
}

.mt4 {
  margin-top: 4px;
}

.analyticsRainfallButton {
  position: absolute;
  display: flex;
  right: 5%;
  text-align: center;
  top: 16%;
}

.analyticsRainfallButtonLeft {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 6px 9px 6px 6px;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  font-size: 0.7rem;

  text-align: center;
}

.analyticsRainfallButtonRight {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 6px 7px;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  font-size: 0.7rem;
}

@media screen and (max-width: 1250px) {
  .heading-area-metdata {
    margin-left: 23px;
    font-size: 0.6rem;
    flex-direction: column;
  }

  .chart-wrapper {
    height: 160px;
  }

  .chartFont {
    font-size: 0.55rem;
  }

  .flex-media {
    display: flex;
    align-items: center;
    :nth-of-type(2) {
      margin-top: 3px;
    }
  }

  .mt4 {
    margin-top: unset;
    margin-left: 5px;
  }

  .analyticsRainfallButtonLeft {
    padding: 5px 10px 4px 6px;
    font-size: 0.6rem;
  }

  .analyticsRainfallButtonRight {
    padding: 3px 4px;
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1250px) {
  .analyticsRainfallButtonLeft,
  .analyticsRainfallButtonRight {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 1150px) {
  .heading-area-metdata {
    font-size: 0.6rem;
  }

  .chart-wrapper {
    height: 155px;
  }
}

@media screen and (min-width: 1700px) {
  .heading-area-metdata {
    font-size: 0.8rem;
  }

  .analyticsRainfallButtonLeft,
  .analyticsRainfallButtonRight {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1900px) {
  .heading-area-metdata {
    font-size: 1rem;
  }

  .analyticsRainfallButtonLeft,
  .analyticsRainfallButtonRight {
    font-size: 0.9rem;
  }
}

/* MOBILE SCREENS  */
@media only screen and (max-width: 600px) {
  .currMonthMobileMargin {
    margin-bottom: 3px;
  }
}
