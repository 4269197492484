.talukaBarContainer {
  display: flex;
  justify-content: flex-start;
  padding: 0.5%;
  align-items: center;
  margin-left: 1%;
}

.talukaBar {
  display: flex;
  width: 30%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: center;
  border: 2px #eaeae8 solid;
  border-radius: 5px;
}

.talukaBarCell {
  text-align: center;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  padding: 0.5% 0;
  border-right: 1px #eaeae8 solid;
}

.talukaTriangleRightIcon {
  padding-top: 0.5%;
  cursor: pointer;
}

.talukaTriangleLeftIcon {
  padding-top: 0.5%;
  cursor: pointer;
}

.talukaTriangleRightIcon:hover {
  color: #3883e6;
}

.talukaTriangleLeftIcon:hover {
  color: #3883e6;
}

@media screen and (max-width: 1250px) {
  .talukaTriangleLeftIcon {
    padding-top: 0.7%;
    font-size: 0.7rem;
  }
  .talukaTriangleRightIcon {
    padding-top: 0.7%;
    font-size: 0.7rem;
  }
  .talukaBarCell {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .talukaTriangleLeftIcon {
    padding-top: 0.5%;
    font-size: 0.8rem;
  }
  .talukaTriangleRightIcon {
    padding-top: 0.5%;
    font-size: 0.8rem;
  }
  .talukaBarCell {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1700px) {
  .talukaTriangleLeftIcon {
    padding-top: 0.5%;
    font-size: 0.85rem;
  }
  .talukaTriangleRightIcon {
    padding-top: 0.5%;
    font-size: 0.85rem;
  }
  .talukaBarCell {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1900px) {
  .talukaTriangleLeftIcon {
    padding-top: 0.5%;
    font-size: 1.05rem;
  }
  .talukaTriangleRightIcon {
    padding-top: 0.5%;
    font-size: 1.05rem;
  }
  .talukaBarCell {
    font-size: 1rem;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 768px) {
  .talukaBarContainer {
    margin-bottom: 10px;
  }

  .talukaBar {
    justify-content: space-around;
  }
}
/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .talukaBarContainer {
    justify-content: center;
    margin-left: unset;
    margin-bottom: 5px;
    padding-top: 6px;
  }

  .talukaBar {
    justify-content: center;
    width: 92%;
    border: unset;
  }

  .talukaBarCell {
    flex-basis: 20%;
    border-right: unset;
  }
}
