@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  font-family: 'Numbers', 'Roboto', sans-serif !important;
  background-color: #f3f3f3 !important;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #868686;
}

@media screen and (max-width: 480px) {
  body::-webkit-scrollbar {
    width: 0;
  }
}
