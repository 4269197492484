.piechartVillageTalukaContainer {
  margin: 0 auto;
  width: 70%;
}

.piechartVillageTaluka {
  width: 70%;
  height: 70%;
  font-size: 0.75rem;
  margin: auto;
}

@media screen and (max-width: 1250px) {
  .piechartVillageTaluka {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .piechartVillageTaluka {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 1700px) {
  .piechartVillageTaluka {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1900px) {
  .piechartVillageTaluka {
    font-size: 0.9rem;
  }
}
