.homePage {
  position: relative;
  margin-top: 1.5vw;
  margin-left: 18%;
  margin-right: 40px;
}

@media screen and (max-width: 1250px) {
  .homePage {
    margin-left: 20%;
  }
}

@media screen and (max-width: 950px) {
  .homePage {
    margin-left: 23%;
  }
}

/* Adding breakpoints for tablets view  */

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 768px) {
  .homePage {
    margin: unset;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;

    -webkit-tap-highlight-color: transparent; /* For iOS Safari */
    /* tap-highlight-color: transparent; For other mobile browsers */
  }
}
