.bellIcon {
  position: absolute;
  cursor: pointer;
  top: 21%;
  right: 5%;
}

.bellIcon:hover {
  animation: shake 1s forwards;
}

.notificationBadge {
  position: absolute;
  top: 12%;
  left: 94.5%;
  font-size: 0.6rem;
  background-color: #ff0000;
  color: #fff;
  padding: 0.2rem 0.37rem;
  border-radius: 100px;
  cursor: pointer;
  z-index: 2;
}

.notificationTimeline {
  position: absolute;
  max-height: 400px;
  min-height: 100px;
  width: 400px;
  border-radius: 10px;
  top: 50%;
  right: 7%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 0.6rem;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 9999;
}

.noNotificationMsg {
  position: absolute;
  top: 44%;
  right: 35%;
  font-size: 0.8rem;
  color: #d8d8d8;
  font-style: italic;
}

.notificationTimeHead {
  font-size: 0.9rem;
  margin-bottom: 2%;
}

.notificationDivider {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.notification {
  position: relative;
  padding: 1rem 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  background-color: #3884e6;
  color: #fff;
  font-size: 0.8rem;
}

.notificationContent {
  display: flex;
  align-items: center;
}

.notificationIcon {
  padding: 0 1rem;
}

.notificationMsg {
  width: 78%;
}

.pdgGeneratorMsg {
  cursor: pointer;
}

.notificationTime {
  margin-top: 2%;
  font-size: 0.6rem;
  font-style: italic;
}

.deleteNotification {
  position: absolute;
  top: 2%;
  right: 0.8%;
  font-size: 1.1rem;
}

.deleteNotification:hover {
  cursor: pointer;
}

/* MEDIA QUERIES STARTS FROM HERE  */

/* web  */
@media (width <= 768px) {
  .bellIcon {
    scale: 2;
    right: 7%;
  }
}
/* mobile  */
@media screen and (max-width:480px) {
  .bellIcon {
    scale: 2;
    right: 6%;
    font-size: .5rem!important;
  }
}

@media screen and (max-width: 1250px) {
  .bellIcon {
    top: 26.5%;
    font-size: 0.65rem;
  }

  .notificationBadge {
    font-size: 0.4rem;
    padding: 0.15rem 0.3rem;
    top: 15%;
  }

  .notificationTimeline {
    max-height: 250px;
    min-height: 60px;
    width: 250px;
  }

  .noNotificationMsg {
    top: 42%;
    right: 30%;
    font-size: 0.65rem;
  }

  .notificationTimeHead {
    font-size: 0.6rem;
  }

  .notification {
    padding: 0.8rem 0.35rem;
    margin-bottom: 0.35rem;
    font-size: 0.65rem;
  }

  .notificationIcon {
    padding: 0 0.8rem;
    font-size: 0.7rem;
  }

  .notificationTime {
    font-size: 0.45rem;
  }

  .deleteNotification {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .bellIcon {
    top: 26.5%;
    font-size: 0.7rem;
  }
  .notificationBadge {
    font-size: 0.5rem;
    padding: 0.15rem 0.35rem;
    top: 15%;
  }

  .notificationTimeline {
    max-height: 300px;
    min-height: 70px;
    width: 300px;
  }

  .noNotificationMsg {
    top: 42%;
    right: 35%;
    font-size: 0.65rem;
  }

  .notificationTimeHead {
    font-size: 0.75rem;
  }

  .notification {
    padding: 0.9rem 0.4rem;
    margin-bottom: 0.4rem;
    font-size: 0.7rem;
  }

  .notificationIcon {
    padding: 0 0.9rem;
    font-size: 0.8rem;
  }

  .notificationTime {
    font-size: 0.5rem;
  }

  .deleteNotification {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1700px) {
  .bellIcon {
    top: 22%;
    font-size: 1.1rem;
  }

  .notificationBadge {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
    top: 12%;
  }

  .notificationTimeline {
    max-height: 450px;
    min-height: 110px;
    width: 450px;
  }

  .noNotificationMsg {
    top: 43%;
    right: 36%;
    font-size: 0.85rem;
  }

  .notificationTimeHead {
    font-size: 1rem;
  }

  .notification {
    padding: 1.1rem 0.7rem;
    margin-bottom: 0.4rem;
    font-size: 0.9rem;
  }

  .notificationIcon {
    padding: 0 1.1rem;
    font-size: 1rem;
  }

  .notificationTime {
    font-size: 0.7rem;
  }

  .deleteNotification {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1900px) {
  .bellIcon {
    top: 22%;
    font-size: 1.25rem;
  }

  .notificationBadge {
    font-size: 0.8rem;
    padding: 0.225rem 0.45rem;
    top: 11%;
  }

  .notificationTimeline {
    max-height: 500px;
    min-height: 120px;
    width: 500px;
  }

  .noNotificationMsg {
    top: 43%;
    right: 36%;
    font-size: 0.9rem;
  }

  .notificationTimeHead {
    font-size: 1.2rem;
  }

  .notification {
    padding: 1.2rem 0.8rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  .notificationIcon {
    padding: 0 1.2rem;
    font-size: 1.1rem;
  }

  .notificationTime {
    font-size: 0.8rem;
  }

  .deleteNotification {
    font-size: 1.3rem;
  }
}
