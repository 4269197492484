.villageProgramContainer {
  display: flex;
  height: 235px;
}

.villageProgramContainerLeft {
  width: 30%;
  margin-left: 5%;
  margin-top: 1.5%;
}

.villageProgramContainerRight {
  width: 50%;
}

.villageProgramContainerRightTwo {
  width: 20%;
  display: grid;
  align-items: flex-end;
  margin-bottom: 20px;
}

.villageProgramGraphContainer {
  display: flex;
  height: 78%;
  align-items: center;
  justify-content: center;
}

.villageChartGraph {
  width: 73%;
  height: 100%;
}

.villageProgramHead {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 1.5%;
}

.villageProgramBox {
  display: flex;
  height: 75%;
  background-color: #f3f3f3;
  padding: 15px;
  font-size: 0.85rem;
  overflow: scroll;
  overflow-x: hidden;
  font-weight: 600;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  text-align: center;

  max-height: 150px;
}

.villageProgramSafe {
  color: #50b432;
  width: 33.33%;
  text-align: left;
}

.villageProgramCritical {
  color: #dddf00;
  width: 33.33%;
  text-align: center;
}

.villageProgramExploited {
  color: #ed561b;
  width: 33.33%;
  text-align: right;
}

.villageProgramSafe div {
  margin-bottom: 5%;
}

.villageProgramCritical div {
  margin-bottom: 5%;
}

.villageProgramExploited div {
  margin-bottom: 5%;
}

.villageProgramGraphHead {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 6%;
  text-align: center;
}

.villageChartStats {
  font-size: 0.75rem;
  font-weight: 600;
}

.villageChartStats p {
  margin: 0.5vw;
}

.talukaUnderProgramButton {
  padding: 6px 8px;
  position: absolute;
  top: 90%;
  right: 6%;
  font-size: 0.7rem;
  font-weight: 600;
  background-color: #3883e6;
  margin: 0;
  cursor: pointer;
  border: none;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
}

.talukaUnderProgramButton:hover {
  background-color: #073f89;
}

/* MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .villageProgramContainer {
    height: 190px;
  }
  .villageProgramHead {
    font-size: 0.65rem;
  }
  .villageProgramBox {
    height: 78%;
  }
  .villageProgramSafe div {
    font-size: 0.65rem;
  }
  .villageProgramCritical div {
    font-size: 0.65rem;
  }
  .villageProgramExploited div {
    font-size: 0.65rem;
  }
  .villageProgramGraphHead {
    font-size: 0.65rem;
  }
  .villageChartStats {
    font-size: 0.65rem;
  }
  .talukaUnderProgramButton {
    font-size: 0.45rem;
    width: 14%;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .villageProgramContainer {
    height: 200px;
  }
  .villageProgramHead {
    font-size: 0.75rem;
  }
  .villageProgramBox {
    height: 78%;
  }
  .villageProgramSafe div {
    font-size: 0.75rem;
  }
  .villageProgramCritical div {
    font-size: 0.75rem;
  }
  .villageProgramExploited div {
    font-size: 0.75rem;
  }
  .villageProgramGraphHead {
    font-size: 0.75rem;
  }
  .villageChartStats {
    font-size: 0.7rem;
  }
  .talukaUnderProgramButton {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 1700px) {
  .villageProgramContainer {
    height: 245px;
  }
  .villageProgramHead {
    font-size: 1rem;
  }
  .villageProgramBox {
    font-size: 0.7rem;
    height: 75%;
  }
  .villageProgramSafe div {
    font-size: 0.9rem;
  }
  .villageProgramCritical div {
    font-size: 0.9rem;
  }
  .villageProgramExploited div {
    font-size: 0.9rem;
  }
  .villageProgramGraphHead {
    font-size: 1rem;
  }
  .villageChartStats {
    font-size: 0.85rem;
  }
  .talukaUnderProgramButton {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1900px) {
  .villageProgramContainer {
    height: 275px;
  }
  .villageProgramHead {
    font-size: 1rem;
  }
  .villageProgramBox {
    height: 75%;
  }
  .villageProgramSafe div {
    font-size: 1rem;
  }
  .villageProgramCritical div {
    font-size: 1rem;
  }
  .villageProgramExploited div {
    font-size: 1rem;
  }
  .villageProgramGraphHead {
    font-size: 1rem;
  }
  .villageChartStats {
    font-size: 1rem;
  }
  .talukaUnderProgramButton {
    font-size: 0.9rem;
  }
}

/* Adding breakpoints for mobile view  */
@media screen and (max-width: 768px) {
  .bottom-box {
    height: 150px;
  }

  .villageProgramContainerRight {
    margin-top: 1.5%;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .bottom-box {
    flex-direction: column;
    height: unset;
  }

  .villageProgramContainerLeft {
    margin-left: unset;

    width: 93%;
    margin: 5px auto;
  }

  .villageProgramBox {
    box-shadow: unset;
  }

  .villageProgramContainerRight {
    width: 93%;
    margin: 5px auto;
  }

  .villageProgramContainerRightTwo {
    width: 100%;
    width: 93%;
    margin: 5px auto;
  }

  .villageProgramGraphHead {
    text-align: left;
    margin-bottom: 4%;
  }

  .villageChartGraph {
    height: 150px;
    width: 100%;
  }

  .villageChartStats {
    display: flex;
    justify-content: space-between;
  }

  .villageChartStats .safe-critical-exp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.625rem;
  }

  .safe-critical-exp .fullStop {
    display: none;
  }

  .safe-critical-exp span:nth-of-type(1) {
    text-align: center;
  }

  .talukaDetailsButton {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .talukaUnderProgramButton {
    position: relative;
    top: unset;
    right: unset;
    display: flex;
    justify-content: center;
    min-width: 28%;
    max-width: 70%;
    margin-top: 2px;
    margin-bottom: 8px;
    padding: 7px 8px;
    border-radius: 1rem;
  }
}
