.year {
  position: absolute;
  top: 58%;
  right: 7%;
  border: 2px solid #9a9a9a;
}

.year select {
  border: none;
  color: #444444;
  font-weight: 500;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 2px 5px;
}

.year select:focus {
  outline: none;
}

@media screen and (max-width: 1250px) {
  .year select {
    font-size: 0.76rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .year select {
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 1700px) {
  .year select {
    font-size: 0.95rem;
  }
}

@media screen and (min-width: 1900px) {
  .year select {
    font-size: 1.05rem;
  }
}

/* tab and mobile  */
@media (width <= 768px) {
  .year {
    border: none;
    outline: 1px solid rgb(154, 154, 154, 0.8);
    border-radius: 3px;
    background: white;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(1px);
  }
  .year select {
    font-weight: 400;
    background: white;
  }
}
