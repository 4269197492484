.navbar {
  position: relative;
  background-color: #fff;
  padding: 10px 5px;
  box-shadow: 0px 3px 3px #b8b8b880;
  z-index: 1;
}

.navbarContainer {
  margin-left: 18%;
}

.user {
  margin-top: 0.3%;
  width: 20%;
  cursor: pointer;
}

.username {
  display: inline-block;
  margin-left: 4%;
  color: #444444;
  font-size: 0.75rem;
  font-weight: 500;
}

.triangleDownIcon {
  font-size: 0.5rem;
  color: #8892ac;
}

.userIcon {
  color: #8892ac;
}

.userDropdownContent {
  display: none;
  position: absolute;
  left: 17%;
  padding: 5px;
  width: 3%;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0.75%;
}

.userDropdownContent div:hover {
  background-color: #3784e6;
  color: #f1f1f1;
}

.notificationTimeline {
  position: absolute;
  max-height: 400px;
  min-height: 100px;
  width: 400px;
  border-radius: 10px;
  top: 50%;
  right: 7%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 0.6rem;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 2;
}

.notificationTimeHead {
  font-size: 0.9rem;
  margin-bottom: 2%;
}

.notificationDivider {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.notificationBadge {
  position: absolute;
  top: 12%;
  left: 94.5%;
  font-size: 0.6rem;
  background-color: #ff0000;
  color: #fff;
  padding: 0.2rem 0.37rem;
  border-radius: 100px;
  cursor: pointer;
  z-index: 2;
}

.noNotificationMsg {
  position: absolute;
  top: 44%;
  right: 35%;
  font-size: 0.8rem;
  color: #d8d8d8;
  font-style: italic;
}

.notification {
  position: relative;
  padding: 1rem 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  background-color: #3884e6;
  color: #fff;
  font-size: 0.8rem;
}

.notificationContent {
  display: flex;
  align-items: center;
}

.notificationTime {
  margin-top: 2%;
  font-size: 0.6rem;
  font-style: italic;
}

.notificationIcon {
  padding: 0 1rem;
}

.notificationMsg {
  width: 78%;
}

.pdgGeneratorMsg {
  cursor: pointer;
}

.deleteNotification {
  position: absolute;
  top: 2%;
  right: 0.8%;
  font-size: 1.1rem;
}

.deleteNotification:hover {
  cursor: pointer;
}

.show {
  display: block;
}

.search {
  position: absolute;
  top: 18%;
  right: 20%;
  width: 20%;
  border-radius: 15px;
  padding: 0 5px;
  border: 2px solid #d1d1d1;
}

.search input {
  width: 90%;
  float: right;
  margin-top: 0.3%;
  border: none;
}

.search input:focus {
  outline: none;
}

.searchIcon {
  position: relative;
  top: 2px;
  left: 5px;
  color: #b4b4b4;
}

.language {
  position: absolute;
  top: 7%;
  right: 8%;
}

.navbar .languageOptions {
  font-size: 0.8rem;
}

.language select {
  border: none;
  color: #444444;
  font-weight: 500;
  font-size: 0.75rem;
}

.language select:focus {
  outline: none;
}

.bellIcon {
  position: absolute;
  cursor: pointer;
  top: 21%;
  right: 5%;
}

.bellIcon:hover {
  animation: shake 1s forwards;
}

@keyframes shake {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
}

.downloadIcon {
  position: absolute;
  top: 17%;
  right: 6.5%;
  font-size: 1.4rem;
}

.homeHeading {
  margin-top: 1.4%;
  font-size: 1.2rem;
  font-weight: 600;
  width: 25%;
}

#month {
  width: 24.01%;
  overflow: scroll;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 58.5%;
  right: 18%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: center;
  border: 2px #eaeae8 solid;
  border-radius: 5px;
}

.month-cell {
  text-align: center;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5% 0;
  border-right: 1px #eaeae8 solid;
}

.calendarTriangleLeftIcon {
  position: absolute;
  top: 62%;
  right: 43%;
  cursor: pointer;
}

.calendarTriangleRightIcon {
  position: absolute;
  top: 62%;
  right: 16%;
  cursor: pointer;
}

.year {
  position: absolute;
  top: 58%;
  right: 7%;
  border: 2px solid #9a9a9a;
}

.year select {
  border: none;
  color: #444444;
  font-weight: 500;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 2px 5px;
}

.year select:focus {
  outline: none;
}

.triangleOff {
  color: #c2c2c2;
  cursor: pointer;
}

#season {
  position: absolute;
  top: 58%;
  right: 18%;
  border: 2px solid #9a9a9a;
}

#season select {
  border: none;
  color: #444444;
  font-weight: 500;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 2px 5px;
}

#season select:focus {
  outline: none;
}

.plan-for-season {
  margin-right: 25px;
}

.mobileUnitChangeIsVisible {
  display: none;
}

@media screen and (max-width: 1250px) {
  .plan-for-season {
    font-size: 15px;
  }

  .navbarContainer {
    margin-left: 18.5%;
  }
  .user .userIcon {
    font-size: 0.65rem;
  }
  .user .triangleDownIcon {
    font-size: 0.42rem;
  }
  .user .username {
    font-size: 0.65rem;
  }
  .search {
    right: 25%;
  }
  .language {
    right: 11%;
  }
  .languageOptions {
    font-size: 0.6rem;
  }
  .search .searchIcon {
    top: 1px;
    font-size: 0.65rem;
  }

  .language select {
    font-size: 0.65rem;
  }
  .bellIcon {
    top: 26.5%;
    font-size: 0.65rem;
  }
  .downloadIcon {
    top: 23.5%;
    right: 7%;
    font-size: 0.85rem;
  }
  .homeHeading {
    font-size: 0.95rem;
  }
  .calendarTriangleLeftIcon {
    top: 64%;
    right: 49%;
    font-size: 0.6rem;
  }
  .calendarTriangleRightIcon {
    top: 64%;
    right: 22.3%;
    font-size: 0.6rem;
  }
  #month {
    right: 24%;
  }
  .month-cell {
    font-size: 0.65rem;
  }
  .year select {
    font-size: 0.76rem;
  }
  .notificationTimeline {
    max-height: 250px;
    min-height: 60px;
    width: 250px;
  }
  .notificationTimeHead {
    font-size: 0.6rem;
  }
  .notificationBadge {
    font-size: 0.4rem;
    padding: 0.15rem 0.3rem;
    top: 15%;
  }
  .noNotificationMsg {
    top: 42%;
    right: 30%;
    font-size: 0.65rem;
  }
  .notification {
    padding: 0.8rem 0.35rem;
    margin-bottom: 0.35rem;
    font-size: 0.65rem;
  }
  .deleteNotification {
    font-size: 0.8rem;
  }
  .notificationIcon {
    padding: 0 0.8rem;
    font-size: 0.7rem;
  }
  .notificationTime {
    font-size: 0.45rem;
  }
}

@media screen and (max-width: 950px) {
  .navbarContainer {
    margin-left: 23%;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .user .userIcon {
    font-size: 0.7rem;
  }
  .user .triangleDownIcon {
    font-size: 0.45rem;
  }
  .user .username {
    font-size: 0.7rem;
  }
  .search .searchIcon {
    top: 1px;
    font-size: 0.7rem;
  }

  .language select {
    font-size: 0.7rem;
  }
  .languageOptions {
    font-size: 0.65rem;
  }
  .bellIcon {
    top: 26.5%;
    font-size: 0.7rem;
  }
  .downloadIcon {
    top: 23.5%;
    font-size: 0.95rem;
  }
  .homeHeading {
    font-size: 1.1rem;
  }
  .calendarTriangleLeftIcon {
    top: 64.5%;
    font-size: 0.7rem;
  }
  .calendarTriangleRightIcon {
    top: 64.5%;
    font-size: 0.7rem;
  }
  .month-cell {
    font-size: 0.8rem;
  }
  .year select {
    font-size: 0.75rem;
  }
  .notificationTimeline {
    max-height: 300px;
    min-height: 70px;
    width: 300px;
  }
  .notificationTimeHead {
    font-size: 0.75rem;
  }
  .notificationBadge {
    font-size: 0.5rem;
    padding: 0.15rem 0.35rem;
    top: 15%;
  }
  .noNotificationMsg {
    top: 42%;
    right: 35%;
    font-size: 0.65rem;
  }
  .notification {
    padding: 0.9rem 0.4rem;
    margin-bottom: 0.4rem;
    font-size: 0.7rem;
  }
  .deleteNotification {
    font-size: 0.9rem;
  }
  .notificationIcon {
    padding: 0 0.9rem;
    font-size: 0.8rem;
  }
  .notificationTime {
    font-size: 0.5rem;
  }
}

@media screen and (min-width: 1700px) {
  .user .userIcon {
    font-size: 0.85rem;
  }
  .user .triangleDownIcon {
    font-size: 0.6rem;
  }
  .user .username {
    font-size: 0.85rem;
  }
  .search .searchIcon {
    top: 2px;
    font-size: 1.1rem;
  }

  .language select {
    font-size: 0.85rem;
  }
  .languageOptions {
    font-size: 0.9rem;
  }
  .bellIcon {
    top: 22%;
    font-size: 1.1rem;
  }
  .downloadIcon {
    top: 20%;
    font-size: 1.5rem;
  }
  .homeHeading {
    font-size: 1.3rem;
  }
  .calendarTriangleLeftIcon {
    top: 62%;
    font-size: 1.1rem;
  }
  .calendarTriangleRightIcon {
    top: 62%;
    font-size: 1.1rem;
  }
  .month-cell {
    font-size: 0.9rem;
  }
  .year select {
    font-size: 0.95rem;
  }
  .notificationTimeline {
    max-height: 450px;
    min-height: 110px;
    width: 450px;
  }
  .notificationTimeHead {
    font-size: 1rem;
  }
  .notificationBadge {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
    top: 12%;
  }
  .noNotificationMsg {
    top: 43%;
    right: 36%;
    font-size: 0.85rem;
  }
  .notification {
    padding: 1.1rem 0.7rem;
    margin-bottom: 0.4rem;
    font-size: 0.9rem;
  }
  .deleteNotification {
    font-size: 1.2rem;
  }
  .notificationIcon {
    padding: 0 1.1rem;
    font-size: 1rem;
  }
  .notificationTime {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1900px) {
  .user .userIcon {
    font-size: 0.95rem;
  }
  .user .triangleDownIcon {
    font-size: 0.7rem;
  }
  .user .username {
    font-size: 0.95rem;
  }
  .search .searchIcon {
    top: 3px;
    font-size: 1.2rem;
  }
  .search input {
    margin-top: 0.9%;
  }

  .language select {
    font-size: 0.95rem;
  }
  .bellIcon {
    top: 22%;
    font-size: 1.25rem;
  }
  .downloadIcon {
    top: 20%;
    font-size: 1.65rem;
  }
  .homeHeading {
    font-size: 1.5rem;
  }
  .calendarTriangleLeftIcon {
    top: 63%;
    font-size: 1.3rem;
  }
  .calendarTriangleRightIcon {
    top: 63%;
    font-size: 1.3rem;
  }
  .month-cell {
    font-size: 1.05rem;
  }
  .year select {
    font-size: 1.05rem;
  }
  .notificationTimeline {
    max-height: 500px;
    min-height: 120px;
    width: 500px;
  }
  .notificationTimeHead {
    font-size: 1.2rem;
  }
  .notificationBadge {
    font-size: 0.8rem;
    padding: 0.225rem 0.45rem;
    top: 11%;
  }
  .noNotificationMsg {
    top: 43%;
    right: 36%;
    font-size: 0.9rem;
  }
  .notification {
    padding: 1.2rem 0.8rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  .deleteNotification {
    font-size: 1.3rem;
  }
  .notificationIcon {
    padding: 0 1.2rem;
    font-size: 1.1rem;
  }
  .notificationTime {
    font-size: 0.8rem;
  }
}

/* Tabs  */
@media (width <= 768px) {
  .navbar {
    height: 10vh;
    position: sticky;
    top: 0;
    z-index: 1;
    -webkit-tap-highlight-color: transparent;
  }

  .navbarContainer {
    height: 100%;
    margin-inline: 1rem;
    display: grid;
    align-items: center;
    gap: 0.2rem;
  }

  .menuIconContainer {
    position: absolute;
    display: inline-block;
    cursor: pointer;
  }

  #menuIcon {
    transform: scale(1.3);
    filter: invert(0.4);
  }

  .user {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-0.5rem);
    transform: translate(2rem);
    margin-left: 1.2rem;
  }

  .userIcon {
    display: none;
  }

  .triangleDownIcon {
    display: none;
  }

  .user .username {
    display: flex;
    gap: 0.25rem;
    font-size: 0.75rem;
  }

  .language {
    top: 12%;
    right: 15%;
  }

  .homeHeading {
    font-size: 0.9rem;
    color: rgb(60, 60, 60);
  }

  .planForSeasonMobile {
    display: none;
  }

  .mobileUnitChangeIsVisible {
    display: block;
    position: absolute;
    top: 58%;
    right: 20%;
  }
}

/* mobile  */
@media (width <= 480px) {
  .navbar {
    height: 10vh;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .navbarContainer {
    height: 100%;
    margin-inline: 1rem;
    display: grid;
    align-items: center;
    gap: 0.2rem;
  }

  .menuIconContainer {
    position: absolute;
    display: inline-block;
    cursor: pointer;
  }

  #menuIcon {
    transform: scale(1.3);
    filter: invert(0.4);
  }

  .user {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-0.5rem);
    transform: translate(2rem);
    margin-left: 1.2rem;
  }

  .userIcon {
    display: none;
  }

  .triangleDownIcon {
    display: none;
  }

  .user .username {
    display: flex;
    gap: 0.25rem;
    font-size: 0.75rem;
  }

  .language {
    top: 12%;
    right: 15%;
  }

  .homeHeading {
    font-size: 0.9rem;
    color: rgb(60, 60, 60);
  }

  .userDropdownContent {
    min-width: 140px;
  }
  .userDropdownContent > div {
    font-size: 13px;
  }

  .plan-for-season {
    margin-right: unset;
  }

  .mobileUnitChangeIsVisible {
    right: 31%;
  }
}
