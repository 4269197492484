.header {
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 26px;
}

.content {
  background-color: #e2faff;
  border-radius: 15px;
  border: 1px solid #abf1ff;
  padding: 10px;
  margin: 7px;
}

.title {
  font-size: 11px;
  font-weight: 600;
}

.contentWrapper {
  margin-left: 10px;
  margin-top: 5%;
}

.sectionHeader {
  margin-top: 8%;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 5%;
}

.sectionIcon {
  height: 22px;
  width: 22px;
  margin-right: 10px;
}

.sectionSpacer {
  width: 38px;
}

.sectionContent {
  margin-top: 8px;
}

.dataRow {
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-top: 3%;
}

.dataRowVillage {
  width: 50%;
}

.dataRowValue {
  width: 50%;
  font-weight: 700;
}

.bold {
  font-weight: bold;
}

.sectionDivider {
  margin: 20px 10px;
  border: 0.5px solid #81e1f7;
}
