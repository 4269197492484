.main-wrapper {
  height: 150px;
  padding: 15px;
}
.talukaProgramStatsBoxHead {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 20px;
}
.talukaProgramStatsBoxHead span {
  font-size: 1.6rem;
}
.noStats {
  position: relative;
  left: 50%;
  top: 35%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.issueIcon {
  margin-right: 4%;
}
.talukaProgramPieBoxContent {
  display: flex;
  justify-content: space-between;
  height: 90%;
}
.talukaDripImplement {
  position: relative;
  font-size: 0.65rem;
  width: 50%;
  font-weight: 500;
}
.talukaDripImplement .value {
  font-size: 1.1rem;
  font-weight: 600;
}
.talukaDripImplement .unit {
  font-size: 0.8rem;
  font-weight: 600;
}
.talukaDripImplementContent {
  font-size: 0.75rem;
  position: relative;
  bottom: 8%;
  margin-top: 25px;
}
.talukaDripGraph {
  width: 50%;
  height: 100%;
}

.cropDripArea {
  margin-top: 10px;
}

/* MEDIA QUERIES STARTS FROM HERE  */
@media screen and (max-width: 1250px) {
  .main-wrapper {
    height: 100px;
  }
  .talukaProgramStatsBoxHead {
    font-size: 0.65rem;
  }
  .talukaProgramStatsBoxHead span {
    font-size: 0.6rem;
  }
  .talukaDripImplement {
    font-size: 0.5rem;
  }
  .talukaDripImplement .value {
    font-size: 0.7rem;
  }
  .talukaDripImplement .unit {
    font-size: 0.55rem;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .talukaProgramStatsBoxHead {
    font-size: 0.75rem;
  }
  .talukaDripImplement {
    font-size: 0.65rem;
  }
  .talukaDripImplement .value {
    font-size: 0.9rem;
  }
  .talukaDripImplement .unit {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 1250px) {
  .talukaDripImplementContent {
    font-size: 0.55rem;
  }
  .talukaDripImplement {
    width: 58%;
  }
  .talukaDripGraph {
    width: 42%;
  }
}
@media screen and (min-width: 1700px) {
  .talukaProgramStatsBoxHead {
    font-size: 0.9rem;
  }
  .talukaDripImplement {
    font-size: 0.75rem;
  }
  .talukaDripImplement .value {
    font-size: 1.1rem;
  }
  .talukaDripImplement .unit {
    font-size: 0.8rem;
  }
  .talukaDripImplementContent {
    font-size: 0.85rem;
  }
}
@media screen and (min-width: 1900px) {
  .talukaProgramStatsBoxHead {
    font-size: 1rem;
  }
  .talukaDripImplement {
    font-size: 0.8rem;
  }
  .talukaDripImplement .value {
    font-size: 1.2rem;
  }
  .talukaDripImplement .unit {
    font-size: 0.9rem;
  }
  .talukaDripImplementContent {
    font-size: 0.85rem;
  }
}

/* Adding breakpoints for mobile view  */
/* @media only screen and (max-width: 480px) { */
@media only screen and (max-width: 600px) {
  .main-wrapper {
    height: 5rem;
    padding: 9px;
  }

  .talukaProgramStatsBoxHead {
    font-size: 0.65rem;
    /* letter-spacing: 0.0125rem; */
    font-weight: 400;
    margin-bottom: 10px;
  }

  .area-under-cultivation {
    font-size: 0.5rem;
    font-weight: 300;
    margin-bottom: 3px;
    letter-spacing: 0.0125rem;
  }

  .talukaProgramStatsBoxHead .cropDripArea {
    margin-top: 7px;
    color: #3883e6;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .talukaDripImplement {
    width: 68%;
  }

  .talukaDripGraph {
    width: 32%;
    height: 100%;
  }

  .talukaDripImplement .value {
    font-size: 0.75rem;
  }

  .talukaDripImplement .unit {
    font-size: 0.75rem;
  }

  .talukaDripImplementContent {
    margin-top: 15px;
  }
}
