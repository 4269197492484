.landuseContentRightBottomHead {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 1%;
}

.landuseContentRightBottom .economy {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #3883e6;
  font-weight: 600;
  background-color: #fff;
}

.landuseContentRightBottom .intensive {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #3883e6;
  font-weight: 600;
  color: #fff;
}

.landuseTable {
  height: 165px;
  overflow: scroll;
  overflow-x: hidden;
  margin-top: 4%;
}

/* // MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .landuseContentRightBottomHead {
    font-size: 0.7rem;
  }

  .landuseContentRightBottom .economy,
  .landuseContentRightBottom .intensive {
    font-size: 0.5rem;
  }

  .landuseContentRightBottom .economy,
  .landuseContentRightBottom .intensive {
    font-size: 0.5rem;
  }

  .landuseTable {
    height: 110px;
    margin-top: 8%;
  }

  .landuseTable td {
    font-size: 0.6rem;
  }
  .landuseTable th {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .landuseTable {
    height: 165px;
    margin-top: 10%;
  }

  .landuseTable td {
    font-size: 0.7rem;
  }
  .landuseTable th {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1700px) {
  .landuseContentRightBottomHead {
    font-size: 1rem;
  }

  .landuseTable {
    height: 210px;
    margin-top: 7%;
  }

  .landuseTable td {
    font-size: 0.8rem;
    padding: 1.7%;
  }
  .landuseTable th {
    font-size: 0.8rem;
    padding: 1.7%;
  }
}

@media screen and (min-width: 1900px) {
  .landuseContentRightBottomHead {
    font-size: 1.105rem;
  }

  .landuseTable {
    height: 245px;
    margin-top: 7%;
  }

  .landuseTable td {
    font-size: 0.9rem;
  }
  .landuseTable th {
    font-size: 0.9rem;
  }
}

/* Adding breakpoints for tablets view  */
@media only screen and (max-width: 767px) {
  .landuseTable {
    height: 150px;
    margin-top: 3%;
  }

  .landuseContentRightBottom {
    margin-bottom: 20px;
  }
}
/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .landuseTable {
    height: 150px;
  }

  .landuseContentRightBottom {
    margin-bottom: 20px;
  }
}
