.waterConsumptionContent {
  display: none;
  height: 190px;
  padding: 15px;
}

.analyticsRainfall {
  position: relative;
}

.analyticsRainfallHead {
  font-size: 0.9rem;
}

.analyticsRainfallValue {
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 15px;
}

.thirty {
  width: 30%;
}

.main-heading-rainfall {
  font-size: 0.8rem;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.main-content-rainfall {
  font-size: 0.9rem;
  font-weight: bold;
}

.fullReportButton {
  display: inline-block;
  text-align: center;
  background-color: #3883e6;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 0.7rem;
  box-shadow: 0px 5px 7px #00000029;
  text-decoration: none;
}

.fullReportButton:hover {
  background-color: #073f89;
}

.seventy {
  width: 70%;
}

.evapotranspirationLeftButton {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 6px 9px 6px 6px;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  font-size: 0.7rem;

  text-align: center;
}

.evapotranspirationRightButton {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 6px 7px;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  font-size: 0.7rem;
}


/* MEDIA QUERIES STARTS FROM HERE  */
@media screen and (max-width: 1380px) {
  .thirty {
    width: 35%;
  }

  .seventy {
    width: 65%;
  }
}

@media screen and (max-width: 1250px) {
  .main-heading-rainfall {
    font-size: 0.6rem;
  }
  .main-content-rainfall {
    font-size: 0.7rem;
    font-weight: bold;
  }

  .fullReportButton {
    font-size: 0.6rem;
  }

  .analyticsRainfall {
    height: 170px;
  }

  .analyticsRainfallHead {
    font-size: 0.7rem;
  }

  .analyticsRainfallValue {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 950px) {
  .analyticsRainfall {
    margin-right: unset;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1250px) {
  .analyticsRainfallHead {
    font-size: 0.8rem;
  }

  .analyticsRainfallValue {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1700px) {
  .analyticsRainfall {
    height: unset;
  }

  .analyticsRainfallHead {
    font-size: 1rem;
  }

  .analyticsRainfallValue {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1900px) {
  .analyticsRainfall {
    height: unset;
  }

  .analyticsRainfallHead {
    font-size: 1.1rem;
  }

  .analyticsRainfallValue {
    font-size: 1.45rem;
  }
}

/* mobile screens starts from here */

@media screen and (max-width: 600px) {
  .waterConsumptionContent {
    /* background-color: red; */
    height: auto;
    padding: 15px 0;
  }

  .analyticsRainfall {
    height: auto;
  }

  .evapotranspirationFlexParent {
    flex-direction: column-reverse;
  }

  .thirty {
    width: 100%;
  }

  .seventy {
    width: 100%;
    margin-top: 25px;
  }

  .main-heading-rainfall {
    margin-top: 4px;
  }

  .fullReportButton {
    width: 100%;
    box-sizing: border-box;
  }

  .mobileFlexEvapotranspiration {
    display: flex;
  }

  .mobileFlexEvapotranspirationLeft {
    margin-right: 1.8rem;
  }

  .analyticsRainfallHead {
    margin-left: 2%;
  }

  .analyticsRainfallValue {
    margin-left: 21px;
    margin-top: 3px;
  }

  .landuseAlertButtonParentDiv {
    padding-right: 32px;
  }
}
