.third-item-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .third-item-top .curr-situation p {
    font-weight: bold;
    font-size: 18px;
  }
  .third-item-top .right-buttons {
    display: flex;
    align-items: center;
    min-width: 250px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
  }
  
  .third-item-top .right-buttons .btn1,
  .third-item-top .right-buttons .btn2 {
    font-size: 14px;
    width: 50%;
    text-align: center;
    padding: 6px 4px;
    color: #357ddc;
    cursor: pointer;
  }
  .third-item-top .right-buttons .active-crop-mode1,
  .third-item-top .right-buttons .active-crop-mode2 {
    background: #357ddc;
    border-radius: 20px;
    color: #fff;
  }
  .third-item-top .right-buttons .active-crop-mode1 {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }
  .third-item-top .right-buttons .active-crop-mode2 {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }
  .third-item-top-two {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .third-item-top-two .third-two-right {
    font: italic normal normal 18px/20px Segoe UI;
    letter-spacing: 0px;
    color: #3883e6;
    font-size: 14px;
    margin-top: -15px;
  }
  
  .order-most-least {
    font-size: 15px;
  }
  
  .third-item-three {
    margin: 10px 40px 10px 40px;
  }
  .third-item-three .total-text {
    width: 225px;
    text-align: right;
  }
  .third-item-three .less-padding-top-left {
    width: 56%;
    /* background-color: red; */
  }
  .third-item-three .third-less-padding {
    display: flex;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    padding: 10px 0;
    margin-right: 20px;
  }
  .third-item-three .less-padding-top-right {
    width: 43%;
    /* background-color: pink; */
    display: flex;
    justify-content: space-between;
  }
  .third-item-three .less-padding-top-right .less-padding-right-one {
    width: 50%;
  }
  .third-item-three .less-padding-top-right .less-padding-right-two {
    width: 50%;
    text-align: center;
  }
  .third-item-three .bar-div {
    display: flex;
    margin-bottom: 30px;
  }
  .third-item-three .bar-div-suitebar {
    width: 56%;
    /* background-color: green; */
  }
  .third-item-three .right-two-ones {
    display: flex;
    margin-top: 9px;
    width: 43%;
    text-align: center;
    /* background-color: yellow; */
  }
  .third-item-three .right-two-ones .twelve {
    width: 50%;
  }
  .third-item-three .right-two-ones .twelve p {
    font-weight: bold;
  }
  .third-item-three .right-two-ones .twelve-two {
    font-weight: bold;
    width: 50%;
    display: flex;
    justify-content: center;
  }
  
  .supply-demand-right-scroller {
    max-height: 320px;
  }
  
  .rsuitebar-header-div {
    width: 210px;
  }
  
  .crop-name-map {
    font-size: 15px;
  }
  
  .monsoon {
    font-size: 18px;
  }
  .monsoon span {
    font-size: 20px;
  }
  
  .supply-side-container .runoff-available {
    font-size: 18px;
    margin-top: 60px;
  }
  
  .water-increase-and-available {
    font-size: 18px;
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
    width: 80%;
  }
  
  .supply-side-container .runoff-to-be-arrested {
    font-size: 18px;
    margin-top: 30px;
  }
  
  .bottom-dots {
    display: flex;
  }
  
  /* hide and show  + new additions*/
  .mobileTableHeadingsPlan {
    display: none;
  }
  
  .cropTypePlan {
    font-size: 12px;
  }
  
  .tableLastdivMobile {
    width: 62%;
  }
  
  .cropImages {
    width: 24px;
    height: 24px;
  }
  
  .third-item-three .total-items-calculation-text {
    /* font-size: 13px; */
    display: flex;
    width: 37%;
  }
  
  .supply-side-container {
    margin-top: 40px;
    margin-left: 70px;
  }
  
  .slider-and-warning {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .slider-and-warning .warning-or-ok-war {
    margin-left: 42px;
    width: 180px;
    color: rgba(234, 177, 0, 1);
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  .slider-and-warning .warning-or-ok {
    margin-left: 42px;
    width: 160px;
    color: rgba(18, 203, 48, 1);
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  
  .mobileTotalHeadingsTable {
    display: none;
  }
  
  .bar-div-container {
    margin-top: 22px;
  }
  
  .bottom-last {
    display: flex;
    margin-top: 20px;
    margin-right: 15px;
    margin-left: 15px;
  }
  
  /* MEDIA QUERIES STARTS FROM HERE  */
  
  @media screen and (max-width: 1175px) {
    .rsuitebar-header-div {
      width: 38%;
    }
    .bottom-dots {
      display: none;
    }
  }
  
  @media screen and (max-width: 1360px) {
    .rsuitebar-header-div {
      width: 200px;
    }
  }
  
  @media screen and (max-width: 1300px) {
    .third-item-top .curr-situation p {
      font-size: 16px;
    }
    .third-item-top .right-buttons {
      min-width: 200px;
    }
    .third-item-top .right-buttons .btn1,
    .third-item-top .right-buttons .btn2 {
      font-size: 12px;
    }
    .third-item-top-two .third-two-left {
      font-size: 14px;
    }
    .third-item-top-two .third-two-left .order-most-least {
      font-size: 13px;
    }
    .third-item-top-two .third-two-right {
      font-size: 12px;
    }
  
    .third-item-top .curr-situation p {
      font-size: 16px;
    }
  
    .third-item-top .right-buttons {
      min-width: 200px;
    }
    .third-item-top .right-buttons .btn1,
    .third-item-top .right-buttons .btn2 {
      font-size: 12px;
    }
  
    .third-item-top-two .third-two-left {
      font-size: 14px;
    }
    .third-item-top-two .third-two-left .order-most-least {
      font-size: 13px;
    }
    .third-item-top-two .third-two-right {
      font-size: 12px;
    }
  
    .third-item-three .total-text {
      width: 205px;
      font-size: 14px;
    }
    .third-item-three .total-items-calculation-text {
      font-size: 13px;
    }
    .third-item-three .less-padding-top-left {
      font-size: 14px;
    }
    .third-item-three .less-padding-top-right {
      font-size: 14px;
    }
    .crop-name-map {
      font-size: 13.5px;
    }
  
    .right-two-ones {
      font-size: 13px;
    }
  
    .monsoon {
      font-size: 15px;
    }
    .monsoon span {
      font-size: 18px;
    }
    .supply-side-container .runoff-available {
      font-size: 15px;
    }
    .water-increase-and-available {
      font-size: 15px;
    }
  
    .supply-side-container .runoff-to-be-arrested {
      font-size: 15px;
    }
  }
  
  @media screen and (min-width: 1700px) {
    .supply-demand-right-scroller {
      max-height: 390px;
    }
  
    .rsuitebar-header-div {
      width: 38%;
    }
  }
  
  /* MOBILE SCREENS  */
  @media screen and (max-width: 600px) {
    .third-item-three .third-less-padding {
      display: none;
    }
  
    .third-item-three .bar-div {
      flex-direction: column;
      justify-content: space-between;
    }
  
    .third-item-three .bar-div-suitebar {
      width: 60%;
    }
  
    .third-item-three {
      margin: 10px 20px 10px 20px;
    }
  
    .third-item-three .right-two-ones .twelve .mobileTableHeadingsPlan {
      font-weight: normal;
    }
  
    .mobileTableHeadingsPlan {
      display: block;
      font-weight: normal;
    }
  
    .third-item-three .right-two-ones {
      width: 30%;
      flex-direction: column;
      margin-top: -15px;
      font-size: 0.75rem;
      text-align: right;
    }
  
    .third-item-three .right-two-ones .twelve-two {
      flex-direction: column;
      width: 100%;
    }
  
    .third-item-top .curr-situation p {
      font-size: 0.9rem;
    }
  
    .third-item-top-two .third-two-left {
      font-size: 0.75rem;
    }
  
    .third-item-top {
      margin-bottom: 3px;
    }
  
    .third-item-top-two .third-two-right {
      margin-top: 0px;
      font: unset;
      font-size: 0.6rem;
    }
  
    .third-item-top-two .third-two-left .order-most-least {
      font-size: 0.65rem;
    }
  
    .third-item-top-two {
      margin-bottom: 15px;
    }
  
    .third-item-three .bar-div {
      margin-bottom: 0px;
      flex-direction: row;
    }
  
    .crop-name-map {
      font-size: 0.75rem;
    }
  
    .cropTypePlan {
      font-size: 0.65rem;
    }
  
    .percentageValuePlan {
      font-size: 0.75rem;
    }
  
    .bottom-last {
      display: flex;
      margin-top: 20px;
      margin-right: 15px;
      margin-left: 0px;
    }
  
    .tableLastdivMobile {
      width: 100%;
      display: none;
    }
  
    .third-item-three .total-text {
      width: unset;
      font-size: 14px;
      text-align: left;
    }
  
    .third-item-three .total-items-calculation-text {
      font-size: 0.75rem;
      width: 100%;
    }
  
    .cropImages {
      width: 20px;
      height: 20px;
    }
  
    .third-item-three .right-two-ones .twelve {
      width: 100%;
      height: 50%;
    }
  
    .supply-side-container {
      margin-top: 30px;
      margin-left: unset;
    }
  
    .monsoon {
      font-size: 0.8rem;
    }
    .monsoon span {
      font-size: 0.8rem;
    }
  
    .supply-side-container .runoff-available {
      font-size: 0.8rem;
      margin-top: 20px;
    }
  
    .water-increase-and-available {
      font-size: 0.75rem;
      margin-top: 40px;
      width: 100%;
    }
  
    .supply-side-container .runoff-to-be-arrested {
      font-size: 0.85rem;
      margin-top: 15px;
      margin-bottom: 5px;
    }
  
    .supply-side-container {
      padding: 0 20px;
    }
  
    .slider-and-warning {
      flex-direction: column;
      align-items: unset;
    }
  
    .slider-and-warning .warning-or-ok-war {
      width: 80%;
      margin-left: 0;
      font-size: 0.65rem;
    }
    .slider-and-warning .warning-or-ok {
      width: 80%;
      font-size: 0.65rem;
      margin-left: 0;
    }
  
    .rsuitebar-header-div {
      width: 170px;
    }
  
    .mobileTotalHeadingsTable {
      display: inline-block;
      margin-right: 5px;
      font-weight: normal;
    }
  
    .totalMob {
      font-weight: bold;
    }
  
    .bar-div-container {
      margin-top: 18px;
    }
  
    .third-item-top .right-buttons {
      min-width: 160px;
      margin-bottom: 4px;
    }
    .third-item-top .right-buttons .btn1,
    .third-item-top .right-buttons .btn2 {
      font-size: 0.65rem;
    }
  }
  
  @media screen and (max-width: 370px) {
    .crop-name-map {
      font-size: 0.65rem;
    }
  
    .rsuitebar-header-div {
      width: 140px;
    }
  
    .third-item-three .bar-div-suitebar {
      width: 60%;
    }
  
    .third-item-three .right-two-ones {
      width: 35%;
      flex-direction: column;
      margin-top: -15px;
      font-size: 0.75rem;
      text-align: right;
    }
  }
  