.plan-parent-wrapper {
  position: relative;
  margin-top: 1.5vw;
  margin-left: 18%;
  margin-right: 40px;
}
.plan-parent-wrapper p {
  margin: 0;
}
.plan-grid-item {
  background: #fff;
}

.error-message {
  color: rgba(255, 0, 0, 0.6);
  margin-right: 20px;
  margin-bottom: 5px;
}
.error-message::before {
  content: '⚠ ';
}

.parent-container-main {
  display: flex;
}
.left-side-container {
  width: 44%;
}
.right-side-container {
  width: 56%;
  margin-top: 20px;
  margin-bottom: 30px;
  display: grid;
  position: relative;
}
.right-side-container .for-the-arrows {
  cursor: pointer;
  position: absolute;
  width: 35px;
  height: 90px;
  top: 50%;
  transform: translate(0%, -50%);
  background-color: #e2e2e2;
}
.right-side-container .for-the-arrows-right {
  right: 0;
}

.plan-grid-container {
  display: flex;
}

.item3 {
  border-radius: 12px;
  padding: 12px;
}

.small-screen-item2 {
  display: none;
}

.item2 {
  border-radius: 12px;
}
.item2 .left-last .last-container {
  display: flex;
}
.item2 .left-last .last-container .months-remaining-text {
  font-size: 22px;
}
.item2 .left-last .last-container .last-container-one,
.item2 .left-last .last-container .last-container-two {
  width: 50%;
  padding: 12px;
}
.item2 .left-last .last-container .last-container-two {
  background: rgba(0, 110, 255, 0.28);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

/* ADDING NEW STYLING HERE  */
.saveScenarioHeading {
  margin: 25px 0 10px 0;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

/* MEDIA QUERIES STARTS FROM HERE  */
@media screen and (max-width: 1300px) {
  .right-side-container .for-the-arrows {
    width: 28px;
    height: 65px;
  }
  .right-side-container .for-the-arrows img {
    width: 12px;
    height: 20px;
  }
  .right-side-container .for-the-arrows-right {
    right: 0;
  }

  .percentageIncrease img {
    width: 12px;
    height: 14px;
  }
  .percentageIncrease p {
    font-size: 12px;
  }
}
@media screen and (max-width: 1175px) {
  .parent-container-main {
    flex-direction: column;
  }
  .left-side-container {
    width: 100%;
  }
  .right-side-container {
    width: 100%;
    margin-top: unset;
  }

  .small-screen-item2 {
    display: block;
    margin-top: 16px;
  }
}
@media screen and (max-width: 950px) {
  .plan-parent-wrapper {
    margin-left: 23%;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 768px) {
  .plan-parent-wrapper {
    margin: unset;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;
    -webkit-tap-highlight-color: transparent;
  }

  .right-side-container {
    display: block;
  }

  .item2 .left-last .last-container .months-remaining-text {
    font-size: 0.85rem;
  }
  .right-side-container .for-the-arrows {
    top: 40%;
    height: 45px;
    width: 20px;
  }
  .right-side-container .for-the-arrows img {
    width: 10px;
    height: 15px;
  }

  .item2
    .left-last
    .last-container
    .last-container-one
    .groundwaterLastDurationText,
  .item2 .left-last .last-container .last-container-two .waterSavedText {
    font-size: 0.75rem;
  }

  /* MODAL STYLING  */
  .saveScenarioHeading {
    font-size: 1rem;
  }

  .scenarioTypeModal,
  .modalName {
    font-size: 0.8rem;
  }

  .modalTextField {
    font-size: 0.8rem;
  }

  .nameValidation {
    width: 250px;
  }
}
