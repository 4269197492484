.consumptionAlertContent {
  margin-top: 3%;
  display: none;
}

.consumptionAlertContentKey {
  margin-left: 5%;
  font-size: 0.9rem;
  margin-bottom: 0.5%;
}

.consumptionAlertContentValue {
  font-weight: 600;
  margin-left: 5%;
}

.consumptionAlertContentSats {
  display: flex;
  justify-content: space-between;
}

.consumptionAlertContentSatsLeft {
  margin-left: 5%;
  width: 30%;
}

.consumptionAlertContentSatsLeftBox {
  background-color: #e1ebf7;
  text-align: center;
  margin-top: 15%;
  font-size: 0.65rem;
  font-weight: 600;
  border-radius: 10px;
  padding: 40px 20px 40px 20px;
  width: 6vw;
}

.consumptionAlertButton {
  display: inline-block;
  text-align: center;
  background-color: #c6c6c6;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 0.7rem;
  font-weight: 600;
  margin-top: 8%;
}

.consumptionAlertContentSatsMiddle {
  width: 30%;
}

.consumptionAlertContentSatsMiddleContainer {
  margin: 0 auto;
}

.consumptionAlertContentSatsChart {
  font-size: 0.7rem;
}

.consumptionAlertContentSatsRight {
  font-size: 0.75rem;
  margin-top: 2%;
  font-weight: 600;
  width: 30%;
}

.consumptionAlertContentSatsRight div {
  margin-top: 3%;
  margin-bottom: 3%;
}

.mobileConsumptionAlertParentDiv {
  display: none;
}

.consumptionAlertContentSatsLeftMobile {
  display: none;
}

/* MEDIA QUERIES STARTS FROM HERE */

@media screen and (max-width: 1250px) {
  .consumptionAlertContentKey {
    font-size: 0.7rem;
  }

  .consumptionAlertContentValue {
    font-size: 0.8rem;
  }

  .consumptionAlertButton {
    font-size: 0.6rem;
  }

  .consumptionAlertContentSatsRight {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1250px) {
  .consumptionAlertContentKey {
    font-size: 0.8rem;
  }

  .consumptionAlertContentValue {
    font-size: 0.9rem;
  }

  .consumptionAlertButton {
    font-size: 0.65rem;
  }

  .consumptionAlertContentSatsRight {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1700px) {
  .consumptionAlertContentKey {
    font-size: 1rem;
  }

  .consumptionAlertContentValue {
    font-size: 1.1rem;
  }

  .consumptionAlertButton {
    font-size: 0.8rem;
  }

  .consumptionAlertContentSatsRight {
    font-size: 0.85rem;
  }
}

@media screen and (min-width: 1900px) {
  .consumptionAlertContentKey {
    font-size: 1.1rem;
  }

  .consumptionAlertContentValue {
    font-size: 1.2rem;
  }

  .consumptionAlertButton {
    font-size: 0.9rem;
  }

  .consumptionAlertContentSatsRight {
    font-size: 1rem;
  }
}

/* Mobile screens styling  */
@media screen and (max-width: 600px) {
  .consumptionAlertContent {
    margin-top: unset;
    padding: 15px 0;
  }

  .consumptionAlertContentSats {
    justify-content: center;
  }

  .consumptionAlertContentSatsLeft {
    display: none;
  }

  .consumptionAlertContentSatsMiddle {
    margin-right: 20px;
  }

  .consumptionAlertContentSatsRight {
    width: 50%;
  }

  .mobileConsumptionAlertParentDiv {
    display: block;
    padding: 0 16px;
  }

  .mobileConsumptionAlertButton {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
  }

  .consumptionAlertContentSatsLeftMobile {
    display: block;
    width: 100%;
  }

  .consumptionAlertContentSatsLeftMobile .consumptionAlertContentSatsLeftBox {
    width: 90%;
    margin: 0 auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-sizing: border-box;
  }
}