.villageBarContainer {
  display: flex;
  justify-content: flex-start;
  padding: 0.5%;
  align-items: center;
  margin-left: 1%;
}

.villageTriangleLeftIcon {
  padding-top: 0.5%;
  cursor: pointer;
}

.villageTriangleLeftIcon:hover {
  color: #3883e6;
}

.villageBar {
  display: flex;
  width: 30%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: center;
  border: 2px#eaeae8 solid;
  border-radius: 5px;
}

.villageBarCell {
  text-align: center;
  flex-basis: 33%;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  padding: 0.5% 0;
  border-right: 1px #eaeae8 solid;
}

.villageTriangleRightIcon {
  padding-top: 0.5%;
  cursor: pointer;
}

.villageTriangleRightIcon:hover {
  color: #3883e6;
}

/* // MEDIA QUERIES STARTS FROM HERE */

@media screen and (max-width: 1250px) {
  .villageTriangleLeftIcon {
    padding-top: 0.7%;
    font-size: 0.7rem;
  }

  .villageBarCell {
    font-size: 0.65rem;
  }

  .villageTriangleRightIcon {
    padding-top: 0.7%;
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .villageTriangleLeftIcon {
    font-size: 0.8rem;
  }

  .villageBarCell {
    font-size: 0.7rem;
  }

  .villageTriangleRightIcon {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1700px) {
  .villageBarCell {
    font-size: 0.9rem;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .villageBarContainer {
    -webkit-tap-highlight-color: transparent; /* For iOS Safari */
    /* tap-highlight-color: transparent; For other mobile browsers */
  }

  .villageTriangleLeftIcon,
  .villageTriangleRightIcon {
    font-size: 0.8rem;
  }

  .villageBarContainer {
    justify-content: center;
    margin-left: unset;
    padding-top: 6px;
  }

  .villageBar {
    width: 92%;
    border: unset;
    overflow: scroll;
  }

  .villageBar::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    display: none;
  }

  .villageBarCell {
    flex-basis: 25%;
    border-right: unset;
  }
}
