.runoffGenerated {
  display: none;
  height: 195px;
  padding: 15px;
}

.main-heading-runoff {
  font-size: 0.9rem;
  margin-left: 18px;
}

.current-month {
  font-size: 0.7rem;
}

.main-content-runoff {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 3px;
  margin-left: 18px;
}

.heading-runoff {
  font-size: 0.8rem;
  margin-left: 18px;
  margin-top: 20px;
}

.content-runoff {
  font-size: 0.9rem;

  margin-left: 18px;
  margin-top: 5px;
  color: #000000;
  font-weight: bold;
}
.groundwaterAlertButton {
  display: inline-block;
  text-align: center;
  background-color: #c6c6c6;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 0.7rem;
  box-shadow: 0px 5px 7px #00000029;
  text-decoration: none;
}

.runoffGeneratedFlexLeftSide {
  width: 32%;
}

.runoffGeneratedFlexRightSide {
  width: 68%;
  margin-top: -10px;
}

.mobileButtonRunoffGenerated {
  display: none;
}

.runoffGeneratedGraphHeight {
  height: 190px;
}

/* MEDIA QUERIES STARTS FROM HERE  */
@media screen and (max-width: 1250px) {
  .main-heading-runoff {
    font-size: 0.7rem;
  }

  .groundwaterAlertButton {
    font-size: 0.6rem;
  }

  .main-content-runoff {
    font-size: 0.8rem;
  }

  .heading-runoff {
    font-size: 0.7rem;
  }

  .content-runoff {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 1700px) {
  .groundwaterAlertButton {
    font-size: 0.9rem;
  }

  .main-heading-runoff {
    font-size: 1.1rem;
  }

  .main-content-runoff {
    font-size: 1rem;
  }

  .heading-runoff {
    font-size: 0.9rem;
  }

  .content-runoff {
    font-size: 1rem;
  }
}

/* //mobile screens  */

@media screen and (max-width: 600px) {
  .runoffGenerated {
    height: auto;
    padding: 15px 0;
  }

  .runoffGeneratedFlexParent {
    flex-direction: column-reverse;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .runoffGeneratedFlexLeftSide {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .runoffGeneratedFlexRightSide {
    width: 100%;
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .webButtonRunoffGenerated {
    display: none;
  }

  .content-runoff {
    margin-left: 18px;
    margin-top: 0px;
    font-size: 0.7rem;
  }

  .main-heading-runoff {
    margin-left: 15px;
  }

  .main-content-runoff {
    margin-left: 21px;
  }

  .heading-runoff {
    margin-left: 18px;
    margin-top: 0px;
  }

  .mobileButtonRunoffGenerated {
    display: block;
    padding-right: 22px;
  }
  .mobileButtonRunoffGenerated .groundwaterAlertButton {
    width: 100%;
  }

  .runoffGeneratedGraphHeight {
    height: 175px;
  }

  .runoffGenHeadingTop {
    margin-left: 2%;
  }
}
