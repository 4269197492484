.header {
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 26px;
}

.content {
  background-color: #f4ffe4;
  border-radius: 15px;
  border: 1px solid #a2e841;
  padding: 10px;
  margin: 7px;
}

.villageName {
  font-size: 11px;
  margin-left: 10px;
}

.villageSection {
  margin-top: 5%;
}

.chartContainer {
  height: 19vh;
}

.chartIndex {
  margin-top: 5px;
}

.indexItem {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 10px;
}

.indexColor {
  width: 7px;
  height: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.villageDivider {
  margin: 20px 10px;
  border: 1px solid #a2e841;
}

.sectionDivider {
  margin: 40px 10px;
  border: 2px solid #a2e841;
}
