.groundWaterContentMapWrapper {
  background-color: #fff;
  margin-right: 16px;
  width: 50%;
  height: 530px;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
}

.groundwaterContentLeftHead {
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 3%;
  margin-left: 5%;
}

.groundwaterContentLeftMap {
  margin-top: 2%;
  width: 95%;
  height: 85%;
  padding: 1% 2.5%;
}

.groundwaterContentLeftMapMap {
  width: 100%;
  height: 460px;
}

/* // MEDIA QUERIES STARTS FROM HERE */

@media screen and (max-width: 1250px) {
  .groundWaterContentMapWrapper {
    height: 440px;
  }

  .groundwaterContentLeftHead {
    font-size: 0.75rem;
  }

  .groundwaterContentLeftMapMap {
    height: 385px;
  }
}

@media screen and (max-width: 950px) {
  .groundWaterContentMapWrapper {
    width: 100%;
    margin-bottom: 15px;
  }

  .groundwaterContentLeftMap {
    margin-top: unset;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .groundWaterContentMapWrapper {
    height: 480px;
  }

  .groundwaterContentLeftHead {
    font-size: 0.85rem;
  }

  .groundwaterContentLeftMapMap {
    height: 420px;
  }
}

@media screen and (min-width: 1700px) {
  .groundWaterContentMapWrapper {
    height: 600px;
  }

  .groundwaterContentLeftHead {
    font-size: 1rem;
  }

  .groundwaterContentLeftMapMap {
    height: 520px;
  }
}

@media screen and (min-width: 1900px) {
  .groundWaterContentMapWrapper {
    height: 677px;
  }

  .groundwaterContentLeftHead {
    font-size: 1.15rem;
  }

  .groundwaterContentLeftMapMap {
    height: 580px;
  }
}

/* // tabs and mobile screens  */

@media screen and (max-width: 768px) {
  .groundWaterContentMapWrapper {
    height: 370px;
  }

  .groundwaterContentLeftMapMap {
    height: 298px;
  }
}
@media screen and (max-width: 600px) {
  .groundWaterContentMapWrapper {
    height: 345px;
  }
}
