.views {
  position: absolute;
  z-index: 1;
  bottom: 2.5vh;
  background-color: #fff;
  right: 0.5vw;
  width: auto;
  border-radius: 9px;
  padding: 4px;
  box-shadow: 0px 0px 14.8px -3px #0000001a;
}

.container {
  display: flex;
  border: 1px black dashed;
  height: 100%;
  width: auto;
  align-items: center;
  padding: 8px;
  border-radius: 9px;
  height: 12vh;
  flex-direction: row-reverse;
}

.thumbnailContainer {
  height: 100%;
  border-radius: 9px;
  position: relative;
  text-align: center;
  color: white;
  cursor: pointer;
  box-shadow: 0px 0px 14.8px -3px #0000001a;
}

.thumbnail {
  height: auto;
  display: block;
  height: 100%;
  object-fit: cover;
  border-radius: 9px;
}

.thumbnailOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thumbnailOverlayIcon {
  width: 30px;
}

.thumbnailOverlayText {
  font-weight: 600;
}

.spacer {
  width: 25px;
}

.viewContainer {
  height: 100%;
  border-radius: 9px;
  text-align: center;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.viewImageContainer {
  height: 70%;
  position: relative;
}

.view {
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 9px;
  height: 98%;
  border: 1px solid #9a9a9a;
}

.view:hover {
  box-shadow: 0px 0px 14.8px -3px #0000001a;
}

.viewTitle {
  color: #9a9a9a;
  font-size: 12px;
  margin-top: 2px;
}

.toggle {
  position: absolute;
  left: -10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #d0d0d0;
  color: #505050;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 7.4px 0px #00000012;
  font-size: 10px;
}

.toggle:hover {
  background-color: #000;
  color: white;
}

.checkOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.viewImageContainer.selected::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) -5.79%, #000000 123.8%);
  border-radius: 9px;
}
