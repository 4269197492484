.landuseContentMapWrapper {
  position: relative;
  background-color: #fff;
  margin-right: 1.5%;
  width: 50%;
  height: 530px;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
}

.landuseContentLeftHead {
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 3%;
  margin-left: 5%;
}

.landuseContentLeftMap {
  margin-top: 2%;
  width: 95%;
  height: 85%;
  padding: 1% 2.5%;
}

.landuseContentLeftMapMap {
  width: 100%;
  height: 420px;
}

.landuseMapLegend {
  font-size: 0.8rem;
  margin-top: 1.5%;
}

.landuseMapLegend span {
  cursor: pointer;
}

.dotArea {
  height: 10px;
  width: 10px;
  background-color: #33644e;
  border-radius: 50%;
  display: inline-block;
}

/* // MEDIA QUERIES STARTS FROM HERE */

@media screen and (max-width: 1250px) {
  .landuseContentMapWrapper {
    height: 400px;
  }

  .landuseContentLeftHead {
    font-size: 0.75rem;
  }

  .landuseContentLeftMapMap {
    height: 315px;
  }

  .landuseMapLegend {
    margin-top: 2%;
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 950px) {
  .landuseContentMapWrapper {
    width: 100%;
    height: 430px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .landuseContentMapWrapper {
    height: 505px;
  }

  .landuseContentLeftHead {
    font-size: 0.85rem;
  }

  .landuseContentLeftMapMap {
    height: 400px;
  }

  .landuseMapLegend {
    margin-top: 2%;
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 1700px) {
  .landuseContentMapWrapper {
    height: 600px;
  }

  .landuseContentLeftHead {
    font-size: 1rem;
  }

  .landuseContentLeftMapMap {
    height: 480px;
  }

  .landuseMapLegend {
    margin-top: 2%;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1900px) {
  .landuseContentMapWrapper {
    height: 677px;
  }

  .landuseContentLeftHead {
    font-size: 1.125rem;
  }

  .landuseContentLeftMapMap {
    height: 540px;
  }

  .landuseMapLegend {
    margin-top: 2%;
    font-size: 1.025rem;
  }
}

/* tabs screens  */
@media screen and (max-width: 768px) {
  .landuseContentMapWrapper {
    height: 370px;
  }

  .landuseContentLeftMapMap {
    height: 258px;
  }
}
/*  mobile screens  */
@media screen and (max-width: 600px) {
  .landuseContentMapWrapper {
    height: 345px;
  }
}
