.header {
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 26px;
}

.content {
  background-color: #ffebe8;
  border-radius: 15px;
  border: 1px solid #ffcfc7;
  padding: 10px;
  margin: 7px;
}

.villageName {
  font-size: 11px;
  margin-left: 10px;
}

.villageSection {
  margin-top: 5%;
}

.chartContainer {
  height: 19vh;
}

.chartIndex {
  margin-top: 5px;
}

.indexItem {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 10px;
}

.indiaMarkColor,
.shallowMarkColor,
.submersibleColor {
  width: 7px;
  height: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.indiaMarkColor {
  background-color: #f2a59a;
}

.shallowMarkColor {
  background-color: #f7c0b8;
}

.submersibleColor {
  background-color: #d0a7a1;
}

.divider {
  margin: 20px 10px;
  border: 1px solid #ffb6b6;
}
