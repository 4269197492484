.groundwaterContentRightBottomTopUp {
  font-size: 0.85rem;
}

.groundwaterContentRightBottomTopDown {
  font-weight: 600;
}

.groundwaterContentRightBottomBottom {
  margin-top: 3.5%;
}

.groundwaterContentRightBottomBottom table {
  width: 100%;
  font-size: 0.75rem;
  text-align: center;
  border-bottom: 2px solid #86b8f6;
}

.groundwaterContentRightBottomBottom th,
td {
  padding: 5px;
}

.groundwaterContentRightBottomBottom th {
  border-bottom: 2px solid #86b8f6;
}

.groundwaterContentRightBottomBottom tr:nth-child(odd) {
  background-color: #f3f3f3;
}

.groundwaterContentRightBottomBottom table tr th {
  padding: 1.5%;
}

.groundwaterContentRightBottomBottom table td {
  padding: 1.5%;
}

.groundwaterContentRightBottomBottomTop {
  font-weight: 600;
  margin-bottom: 2%;
}

.groundwaterContentRightBottomBottomTopBottom {
  height: 168px;
  overflow: scroll;
  overflow-x: hidden;
  border-collapse: collapse;
}

/* // MEDIA QUERIES STARTS FROM HERE */

@media screen and (max-width: 1250px) {
  .groundwaterContentRightBottomTopUp {
    font-size: 0.7rem;
  }

  .groundwaterContentRightBottomTopDown {
    font-size: 0.8rem;
  }

  .groundwaterContentRightBottomBottom table {
    font-size: 0.62rem;
  }
  .groundwaterContentRightBottomBottom table tr th {
    padding: 1.2%;
  }
  .groundwaterContentRightBottomBottom table td {
    padding: 1.2%;
  }

  .groundwaterContentRightBottomBottomTop {
    font-size: 0.75rem;
    margin-bottom: 1%;
  }

  .groundwaterContentRightBottomBottomTopBottom {
    height: 140px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .groundwaterContentRightBottomTopUp {
    font-size: 0.75rem;
  }
  .groundwaterContentRightBottomTopDown {
    font-size: 0.9rem;
  }

  .groundwaterContentRightBottomBottom table {
    font-size: 0.65rem;
  }
  .groundwaterContentRightBottomBottom table tr th {
    padding: 1.5%;
  }
  .groundwaterContentRightBottomBottom table td {
    padding: 1.5%;
  }

  .groundwaterContentRightBottomBottomTop {
    font-size: 0.85rem;
    margin-bottom: 1%;
  }

  .groundwaterContentRightBottomBottomTopBottom {
    height: 148px;
  }
}

@media screen and (min-width: 1700px) {
  .groundwaterContentRightBottomTopUp {
    font-size: 0.9rem;
  }
  .groundwaterContentRightBottomTopDown {
    font-size: 1.1rem;
  }

  .groundwaterContentRightBottomBottom table {
    font-size: 0.9rem;
  }
  .groundwaterContentRightBottomBottom table tr th {
    padding: 1.5%;
  }
  .groundwaterContentRightBottomBottom table td {
    padding: 1.5%;
  }

  .groundwaterContentRightBottomBottomTop {
    font-size: 1.1rem;
    margin-bottom: 1.5%;
    margin-top: 1.5%;
  }

  .groundwaterContentRightBottomBottomTopBottom {
    height: 210px;
  }
}

@media screen and (min-width: 1900px) {
  .groundwaterContentRightBottomTopUp {
    font-size: 1rem;
  }
  .groundwaterContentRightBottomTopDown {
    font-size: 1.2rem;
  }

  .groundwaterContentRightBottomBottom table {
    font-size: 1rem;
  }
  .groundwaterContentRightBottomBottom table tr th {
    padding: 1.5%;
  }
  .groundwaterContentRightBottomBottom table td {
    padding: 1.5%;
  }

  .groundwaterContentRightBottomBottomTop {
    font-size: 1.2rem;
    margin-bottom: 1.5%;
    margin-top: 1.5%;
  }

  .groundwaterContentRightBottomBottomTopBottom {
    height: 240px;
  }
}

@media screen and (max-width: 768px) {
  .groundwaterContentRightBottomBottom {
    margin-bottom: 10px;
  }
}
