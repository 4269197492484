:root {
  --layers-height: 85vh;
}

.layers {
  height: var(--layers-height);
  background-color: rgba(255, 255, 255);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  width: 25%;
  z-index: 1;
  box-shadow: 0px 0px 7.4px 0px #00000012;
  padding-left: 16%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.list {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 79vh;
  padding: 1.5vh;
  width: 90%;
  margin: 1.5vh 0;
}

.listHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.icon {
  height: 100%;
  margin: '10px';
  border-radius: '10px';
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  font-size: 13px;
}

.listHeadCheckBox {
  transform: scale(2);
  -webkit-transform: scale(2);
  accent-color: #000;
}

.listItemCheckBox {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  border: 0;
  border-radius: 4px;
  padding: 2px;
  box-sizing: border-box;
}

.toggleLayersView {
  position: absolute;
  bottom: 20vh;
  right: 0;
  width: 45px;
  height: 45px;
  padding: 10px 0;
  margin-right: -22.5px;
  border-radius: 50%;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 7.4px 0px #00000012;
}

.toggleLayersView:hover {
  background-color: #d0d0d0;
  color: #505050;
}

.toggleListView {
  width: 20px;
  height: 20px;
  padding: 5px 0;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

@media screen and (max-width: 950px) {
  .layers {
    padding-left: 20%;
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .layers {
    padding-left: 0;
    width: 30%;
  }
}
