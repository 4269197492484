.tooltipPosition {
  position: absolute;
  display: flex;
  top: -7px;
}

.optionsForm {
  position: absolute;
  right: 0px;
  top: -10px;
}

.optionsSelect {
  height: 30px;
}

.toolTipImg {
  height: 30px;
  width: 30px;
}

.parentDivGenericPlanNav {
  cursor: pointer;
  margin-bottom: 10px;
}

/* SHARE MODAL  */
.shareCompleteDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shareUser {
  min-width: 200px;
  font-size: 13px;
}

.userRole {
  min-width: 160px;
  font-size: 13px;
}

.sharePermissions {
  min-width: 360px;
  font-size: 13px;
}

.sharetoAll {
  font-size: 15px;
}

.shareScenarioHeading {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .backToScenarioText {
    font-size: 0.75rem;
  }

  .parentDivGenericPlanNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: unset;
    margin-bottom: 15px;
  }

  .tooltipPosition {
    position: relative;
    display: flex;
    top: 0px;
  }

  .optionsParentdiv {
    display: flex;
    align-items: center;
  }

  .optionsForm {
    position: relative;
    right: 0px;
    top: 0px;
  }

  .optionsSelect {
    height: 25px;
  }

  .toolTipImg {
    height: 24px;
    width: 24px;
  }

  /* SHARE MODAL  */

  .shareCompleteDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .shareToDivs {
    flex-direction: column;
  }

  .shareUser {
    min-width: unset;
    margin-bottom: 14px;
  }

  .userRole {
    min-width: unset;
  }

  .sharePermissions {
    min-width: unset;
  }

  .userRoleForm,
  .shareToForm,
  .sharePermissionsForm,
  .permsDiv {
    width: 100%;
  }

  .firstSectionContainer {
    width: 100%;
  }

  .sharetoAll {
    font-size: 13px;
  }

  .sharetoAllParent {
    margin-top: 6px;
  }

  .shareScenarioHeading {
    font-size: 1rem;
  }

  .shareToHeading,
  .permissionsHeading {
    font-size: 0.85rem;
  }

  .tooltipText {
    font-size: 0.75rem;
  }
}
