.soilMoistureContent {
  display: none;
  height: 190px;
  padding: 15px;
}

.groundwaterAlertButton {
  display: inline-block;
  text-align: center;
  background-color: #c6c6c6;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 0.7rem;
  box-shadow: 0px 5px 7px #00000029;
  text-decoration: none;
}

.reduced-increased {
  font-size: 0.7rem;
  max-width: 145px;
  margin-left: 18px;
  margin-top: 20px;
}

.main-heading-runoff {
  font-size: 0.9rem;
  margin-left: 18px;
}

.main-content-runoff {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 3px;
  margin-left: 18px;
}

.heading-runoff {
  font-size: 0.8rem;
  margin-left: 18px;
  margin-top: 20px;
}

.content-runoff {
  font-size: 0.9rem;
  margin-left: 18px;
  font-weight: bold;
  margin-top: 5px;
  color: #000000;
}

.soilMoistureFlexContentLeft {
  width: 32%;
}

.soilMoistureFlexContentRight {
  width: 68%;
  margin-top: -30px;
}

.soilMoistureFullReportMobile {
  display: none;
}

.soilMoistureGraphHeight {
  height: 210px;
}

/* MEDIA QUERIES STARTS FROM HERE  */
@media screen and (max-width: 1250px) {
  .main-heading-runoff {
    font-size: 0.75rem;
  }

  .groundwaterAlertButton {
    font-size: 0.6rem;
  }

  .reduced-increased {
    font-size: 0.6rem;
  }

  .main-content-runoff {
    font-size: 0.8rem;
  }

  .heading-runoff {
    font-size: 0.7rem;
  }

  .content-runoff {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1700px) {
  .main-heading-runoff {
    font-size: 1rem;
  }

  .groundwaterAlertButton {
    font-size: 0.9rem;
  }

  .reduced-increased {
    font-size: 0.75rem;
  }

  .main-content-runoff {
    font-size: 1rem;
  }

  .heading-runoff {
    font-size: 0.9rem;
  }

  .content-runoff {
    font-size: 1rem;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .soilMoistureContent {
    height: auto;
    padding: 15px 0px;
  }

  .soilMoistureFlexContentContainer {
    flex-direction: column-reverse;
    margin-top: 8px;
  }

  .soilMoistureFlexContentLeft {
    display: flex;
    width: 100%;
  }

  .soilMoistureFlexContentRight {
    width: 100%;
    margin-top: 10px;
  }
  .soilMoistureFullReport {
    display: none;
  }

  .reduced-increased {
    margin-top: unset;
  }

  .heading-runoff {
    margin-top: unset;
  }

  .content-runoff {
    margin-top: unset;
    font-size: 0.7rem;
    margin-left: 5px;
  }

  .soilMoistureFullReportMobile {
    display: block;
    padding: 0 16px;
  }
  .groundAlertMobileButtonS {
    width: 100%;
    box-sizing: border-box;
  }

  .soilMoistureGraphHeight {
    height: 175px;
  }

  .soilMoistureAlertStatusGraph {
    margin-left: -10px;
  }

  .soilMoistureAlertContentKey {
    margin-left: 2%;
  }

  .main-heading-runoff {
    margin-left: 15px;
  }

  .main-content-runoff {
    margin-left: 21px;
  }
}
