.analytics {
  position: relative;
  margin-top: 1.5vw;
  margin-left: 18%;
  margin-right: 40px;
}

.analyticsOverviewContainer {
  display: flex;
  margin-top: 0.8%;
}

.analyticsOverviewContainerLeft {
  width: 45%;
}

.analyticsOverviewContainerRight {
  width: 55%;
}

@media screen and (max-width: 1250px) {
  .analytics {
    margin-left: 18.5%;
  }

  .analyticsOverviewContainerLeft {
    width: 43%;
  }
}

@media screen and (max-width: 950px) {
  .analytics {
    margin-left: 23%;
    margin-right: 3.2%;
  }

  .analyticsOverviewContainer {
    flex-direction: column;
  }

  .analyticsOverviewContainerLeft {
    width: 100%;
  }

  .analyticsOverviewContainerRight {
    width: 100%;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1250px) {
  .analyticsOverviewContainerLeft {
    width: 43%;
  }
}

@media screen and (max-width: 1350px) {
  .analyticsOverviewContainerRight {
    align-items: flex-end;
    display: grid;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 768px) {
  .analytics {
    margin: unset;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;
    -webkit-tap-highlight-color: transparent;
  }
}
