.noPlanAvailableParent {
  margin-top: 20px;
  min-width: 300px;
  padding: 40px 70px;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, 30%);
  background: #ffffff;
  box-shadow: 0px 6px 5px 0px #00000040;
}

.biAlarm {
  font-size: 80px;
  padding-left: 34%;
  color: #347ddc;
}


.submitPlanBtn{
  background: #347ddc;
  border: 1px solid #347ddc;
  border-radius: 10px;
  padding: 4px 22px;
  margin-right: 15px;
  cursor: pointer;
  color: #fff;
  text-transform: capitalize;
}


.submitPlanBtn:disabled{
  opacity: .4;
}

/* MOBILE SCREENS  */

@media screen and (max-width: 600px) {
  .noPlanAvailableParent {
    min-width: 200px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
  }

  .scheduledFor {
    font-size: 0.9rem;
  }

  .scheduleSelect {
    font-size: 0.8rem;
  }

  .noPlanDropdown {
    font-size: 0.75rem;
  }

  .biAlarm {
    font-size: 70px;
    padding-left: 0;
  }

  .notYetAvailable {
    font-size: 0.8rem;
    padding: 0 10px;
  }
}
