.header {
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 26px;
}

.content {
  background-color: #ffebe8;
  border-radius: 15px;
  border: 1px solid #ffcfc7;
  padding: 10px;
  margin: 7px;
}

.divider {
  margin: 20px 10px;
  border: 1px solid #ffb6b6;
}

.waterLevelContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  position: relative;
}

.level {
  position: relative;
  width: 30px;
  background-color: #f2d1d1;
  border: 1px solid #ccc;
}

.zeroLevel {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
  text-align: right;
  font-size: 12px;
  padding-right: 35px; /* Move the label to the right */
  padding-top: 2px;
  padding-bottom: 2px;
}

.avgLevel {
  position: absolute;
  width: 100%;
  background-color: #f2d1d1;
  border-bottom: 1px solid #ccc;
  text-align: right;
  font-size: 12px;
  color: #555;
  padding-right: 35px; /* Move the label to the right */
}

.wellLevel {
  position: absolute;
  width: 100%;
  background: linear-gradient(to bottom, #f77e7e, #d24d4d);
  color: white;
  text-align: right;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 35px; /* Move the label to the right */
}

.statsContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}

.statsIcon {
  width: 20px;
  margin-right: 10px;
  background-color: #ffb6b6;
}

.statsLabel {
  font-size: 12px;
  display: flex;
  align-items: center;
  width: 75%;
}

.statsValue {
  font-weight: 700;
  text-align: right;
  width: 25%;
}
