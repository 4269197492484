.villageTalukaContainer {
  height: 85%;
  display: flex;
  justify-content: flex-start;
}

.villageTalukaLeftBox {
  width: 35%;
}

.villageTalukaHead {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 4% 10%;
}

.villageTalukaBox {
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 185px;
  background-color: #f3f3f3;
  padding: 7px 15px;
  font-size: 0.8rem;
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 4% 10%;
}

.villageTalukaBox table {
  width: 100%;
}

.villageTalukaBox .col1 {
  text-align: left;
  padding: 1px 2px;
}

.villageTalukaBox .col2 {
  text-align: right;
  margin-top: 27%;
  font-weight: 600;
  padding: 1px 2px;
}

.villageTalukaBox .col1 {
  text-align: left;
  padding: 1px 2px;
}

.villageTalukaBox .col2 {
  text-align: right;
  margin-top: 27%;
  font-weight: 600;
  padding: 1px 2px;
}

.villageTalukaMiddleBox {
  width: 30%;
}

.villagePopulationHead {
  margin: 0% 0% 5% 0%;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
}

.villagePopulationHeadMobile {
  display: none;
}

.villagePopulationLegend {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 3%;
  font-size: 0.65rem;
  font-weight: 600;
  text-align: center;
}

.dotPopulation {
  height: 8px;
  width: 8px;
  background-color: #c1c1c1;
  border-radius: 50%;
  display: inline-block;
}

.villageTalukaRightBox {
  width: 25%;
  font-size: 0.85rem;
}

.villageTalukaRightBox .sameRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}

.villageTalukaRightBox .waterHead {
  margin-top: 6%;
}

.villageTalukaRightBox .water {
  margin-bottom: 6%;
  font-size: 1.7rem;
}

.villageTalukaRightBox .value {
  font-weight: 600;
}

.villageTalukaRightBox .color {
  color: #84b5f7;
}

.villageTalukaRightBox .sameRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}

.talukaDripImplement .value {
  font-size: 1.1rem;
  font-weight: 600;
}

.villageTalukaRightBox .waterHead {
  margin-top: 6%;
}

.villageTalukaRightBox .water {
  margin-bottom: 6%;
  font-size: 1.7rem;
}

.villageUnderTalukaButton {
  padding: 6px 1px;
  position: absolute;
  top: 78%;
  right: 6%;
  width: 10%;
  font-size: 0.7rem;
  font-weight: 600;
  background-color: #3883e6;
  cursor: pointer;
  margin: 0;
  border: none;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
}

.villageUnderTalukaButton:hover {
  background-color: #073f89;
}

/* adding new styling */
.villageProgramGraphContainer {
  display: flex;
  height: 50%;
  align-items: center;
  justify-content: center;
}

.villageChartGraph {
  width: 73%;
  height: 100%;
}

.mobileRainfallHeading {
  display: none;
}
/* adding new styling */

/* // MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .villageTalukaHead {
    font-size: 0.65rem;
  }

  .villageTalukaBox {
    margin-top: 1.2%;
    font-size: 0.65rem;
    height: 160px;
  }

  .villagePopulationHead {
    font-size: 0.65rem;
  }

  .villagePopulationLegend {
    left: 50%;
  }
  .villagePopulationLegend {
    font-size: 0.6rem;
  }

  .villageTalukaRightBox {
    font-size: 0.65rem;
  }
  .villageTalukaRightBox .sameRow {
    margin-bottom: 3%;
  }
  .villageTalukaRightBox .waterHead {
    margin-top: 7%;
  }
  .villageTalukaRightBox .water {
    font-size: 1.4rem;
    margin-bottom: 7%;
  }

  .villageTalukaRightBox .sameRow {
    margin-bottom: 3%;
  }

  .villageTalukaRightBox .value {
    font-weight: 600;
  }

  .talukaDripImplement .value {
    font-size: 0.8rem;
  }

  .villageTalukaRightBox .water {
    font-size: 1.4rem;
    margin-bottom: 7%;
  }

  .villageUnderTalukaButton {
    font-size: 0.45rem;
    padding: 7px 12px;
    width: 14%;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .villageTalukaHead {
    font-size: 0.8rem;
  }

  .villageTalukaBox {
    margin-top: 1.2%;
    font-size: 0.75rem;
    height: 170px;
  }

  .villagePopulationHead {
    font-size: 0.8rem;
  }

  .villagePopulationLegend {
    left: 50%;
  }
  .villagePopulationLegend {
    font-size: 0.6rem;
  }

  .villageTalukaRightBox {
    font-size: 0.75rem;
  }
  .villageTalukaRightBox .sameRow {
    margin-bottom: 2%;
  }
  .villageTalukaRightBox .waterHead {
    margin-top: 6%;
  }
  .villageTalukaRightBox .water {
    font-size: 1.6rem;
    margin-bottom: 6%;
  }

  .villageTalukaRightBox .sameRow {
    margin-bottom: 2%;
  }

  .talukaDripImplement .value {
    font-size: 0.9rem;
  }

  .villageTalukaRightBox .water {
    font-size: 1.6rem;
    margin-bottom: 6%;
  }

  .villageUnderTalukaButton {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 1700px) {
  .villageTalukaHead {
    font-size: 1rem;
  }

  .villageTalukaBox {
    font-size: 0.9rem;
    height: 200px;
  }

  .villagePopulationHead {
    font-size: 1rem;
  }

  .villagePopulationLegend {
    font-size: 0.75rem;
  }

  .villageTalukaRightBox {
    font-size: 0.95rem;
  }
  .villageTalukaRightBox .sameRow {
    margin-bottom: 2%;
  }
  .villageTalukaRightBox .water {
    margin-bottom: 7%;
    font-size: 1.8rem;
  }
  .villageTalukaRightBox .waterHead {
    margin-top: 7%;
  }

  .talukaDripImplement .value {
    font-size: 1.1rem;
  }

  .villageTalukaRightBox .water {
    margin-bottom: 7%;
    font-size: 1.8rem;
  }

  .villageUnderTalukaButton {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1900px) {
  .villageTalukaHead {
    font-size: 1.1rem;
  }

  .villageTalukaBox {
    font-size: 1rem;
    height: 230px;
  }

  .villagePopulationHead {
    font-size: 1rem;
  }

  .villagePopulationLegend {
    font-size: 0.85rem;
  }

  .villageTalukaRightBox {
    font-size: 1.05rem;
  }
  .villageTalukaRightBox .sameRow {
    margin-bottom: 2%;
  }
  .villageTalukaRightBox .water {
    margin-bottom: 7%;
    font-size: 1.9rem;
  }
  .villageTalukaRightBox .waterHead {
    margin-top: 7%;
  }

  .talukaDripImplement .value {
    font-size: 1.2rem;
  }

  .villageTalukaRightBox .water {
    margin-bottom: 7%;
    font-size: 1.9rem;
  }

  .villageUnderTalukaButton {
    font-size: 0.9rem;
  }
}

/* Adding breakpoints for tabs view  */
@media only screen and (max-width: 768px) {
  .villageTalukaLeftBox {
    width: 40%;
  }

  .villageProgramGraphContainer {
    height: 40%;
  }

  .villageTalukaMiddleBox {
    margin-top: 2%;
  }

  .villageTalukaRightBox {
    margin-top: 3%;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .villageTalukaMiddleBox {
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .villagePopulationLegend {
    margin: unset;
    margin-top: 20px;
    flex-direction: column;
    width: 50%;
  }

  .villagePopulationLegendHumans,
  .villagePopulationLegendCattles,
  .villagePopulationLegendSheeps,
  .villagePopulationLegendPoultries {
    display: flex;
  }

  .legendHeading {
    margin-bottom: 10px;
    width: 70px;
    text-align: left;
  }
  .legendValue {
    width: 60px;
    text-align: left;
  }

  .villagePopulationHead {
    display: none;
  }

  .villagePopulationHeadMobile {
    display: block;
    margin: 0% 0% 5% 0%;
    text-align: left;
    width: 100%;
    font-size: 0.65rem;
    font-weight: 600;
  }

  .villageTalukaContainer {
    flex-direction: column;
  }

  .villageTalukaHead {
    margin: 10px auto;
  }

  .villageTalukaLeftBox {
    margin-left: unset;

    width: 93%;
    margin: 5px auto;
  }

  .villageTalukaBox {
    box-shadow: unset;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .villageTalukaRightBox {
    width: 93%;
    margin: 5px auto;
  }

  .villageProgramContainerRightTwo {
    width: 100%;
    width: 93%;
    margin: 5px auto;
  }

  .villageProgramGraphHead {
    text-align: left;
    margin-bottom: 4%;
  }

  .villageProgramGraphContainer {
    width: 50%;
    flex-direction: column;
    margin-right: 15px;
  }

  .villageChartGraph {
    height: 100px;
    width: 100px;
  }

  .villageChartStats {
    display: flex;
    justify-content: space-between;
  }

  .villageChartStats .safe-critical-exp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.625rem;
  }

  .safe-critical-exp .fullStop {
    display: none;
  }

  .safe-critical-exp span:nth-of-type(1) {
    text-align: center;
  }

  .talukaDetailsButton {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .talukaUnderProgramButton {
    position: relative;
    top: unset;
    right: unset;
    display: flex;
    justify-content: center;
    min-width: 28%;
    max-width: 70%;
    margin-top: 2px;
    margin-bottom: 8px;
    padding: 7px 8px;
    border-radius: 1rem;
  }

  .villageTalukaRightBox .water {
    font-size: 0.9rem;
    margin-bottom: 3%;
  }

  .villageDetailsButton {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .villageUnderTalukaButton {
    position: relative;
    top: unset;
    right: unset;
    display: flex;
    justify-content: center;
    min-width: 28%;
    max-width: 70%;
    margin-top: 2px;
    margin-bottom: 8px;
    padding: 7px 8px;
    border-radius: 1rem;
  }

  .mobileRainfallHeading {
    display: block;
  }

  .rowsParentMobile {
    padding: 7px 15px;
  }

  .villageTalukaRightBox .value {
    padding-right: 2px;
  }

  .villageTalukaRightBox .waterHead {
    margin-top: unset;
  }

  .mobileWaterHeadFlex {
    display: flex;
    justify-content: space-between;
  }
}
