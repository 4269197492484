.surfacewaterTableHead {
  font-size: 0.9rem;
  font-weight: 600;
}

.surfacewaterTable {
  margin-top: 4%;
  width: 100%;
  text-align: center;
}

.surfacewaterTable th {
  font-weight: 500;
  color: #fff;
  padding: 1.8%;
  font-size: 0.8rem;
}

.surfacewaterTable {
  border: 2px solid #e8e8e8;
  border-collapse: collapse;
}

.surfacewaterTable td {
  padding: 8px 0;
  font-size: 0.7rem;
  padding: 2.5%;
}

.surfacewaterTable tr:nth-child(odd) {
  background-color: #f3f3f3;
}

/* // MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1250px) {
  .surfacewaterTableHead {
    font-size: 0.75rem;
  }

  .surfacewaterTable td {
    font-size: 0.65rem;
    padding: 2.2%;
  }
  .surfacewaterTable th {
    font-size: 0.65rem;
    padding: 2.2%;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .surfacewaterTableHead {
    font-size: 0.85rem;
  }

  .surfacewaterTable td {
    font-size: 0.7rem;
    padding: 2.2%;
  }
  .surfacewaterTable th {
    font-size: 0.7rem;
    padding: 2.2%;
  }
}

@media screen and (min-width: 1700px) {
  .surfacewaterTableHead {
    font-size: 1rem;
  }

  .surfacewaterTable {
    margin-top: 4%;
  }

  .surfacewaterTable td {
    font-size: 0.85rem;
    padding: 2.5%;
  }
  .surfacewaterTable th {
    font-size: 0.85rem;
    padding: 2%;
  }
}

@media screen and (min-width: 1900px) {
  .surfacewaterTableHead {
    font-size: 1.1rem;
  }

  .surfacewaterTable {
    margin-top: 4%;
  }

  .surfacewaterTable td {
    font-size: 1rem;
    padding: 2.25%;
  }
  .surfacewaterTable th {
    font-size: 1rem;
    padding: 1.5%;
  }
}

/* for mobile  */

@media screen and (max-width: 480px) {
  .surfacewaterTable td {
    font-size: 0.6rem;
  }

  .surfacewaterTable {
    margin-bottom: 10px;
  }
}
