.advisory-preview-wrapper {
    position: relative;
    margin-top: 1.5vw;
    margin-left: 18%;
    margin-right: 40px;
  }
  
  .backToScenario {
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  @media screen and (max-width: 950px) {
    .advisory-preview-wrapper {
      margin-left: 23%;
    }
  }
  
  .resetsave button {
    padding: 5px 28px;
    color: #ffffff;
    border: 1px solid #357ddc;
    background: #357ddc;
    outline: none;
    border-radius: 11px;
    cursor: pointer;
    box-shadow: 0px 5px 7px #00000029;
  }
  .advisory-preview-wrapper p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .top-section-demand {
    width: 92%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  .top-section-demand .demand-heading p {
    font-size: 20px;
    font-weight: bold;
  }
  .top-section-demand .demand-tcm .tcm-amount {
    font-size: 24px;
    font-weight: bold;
  }
  .top-section-demand .demand-tcm .tcm-amount img {
    margin-right: 5px;
    height: 14px;
    width: 14px;
  }
  .top-section-demand .demand-tcm .tcm-amount-percent {
    font-size: 12px;
    margin-left: 18px;
  }
  .top-section-demand .centered {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .second-section-overall-change {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
  }
  .second-section-overall-change .current-drip-percent {
    font-size: 20px;
    font-weight: bold;
    color: #079f4e;
  }
  .second-section-overall-change .change-in-drip .overall-change-para {
    margin-bottom: 5px;
  }
  .second-section-overall-change .change-in-drip .overall-amount-para {
    color: #079f4e;
    font-size: 20px;
    font-weight: bold;
  }
  .second-section-overall-change .change-in-drip .supply {
    color: #ab3218;
  }
  .advisory-preview-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: minmax(185px, auto);
    grid-template-rows: 185px 185px 185px;
    grid-gap: 20px;
  }
  @media screen and (max-width: 1380px) {
    .advisory-preview-container {
      grid-template-columns: 1fr 1fr;
    }
  }
  .advisoryitems {
    padding: 15px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background: #ffffff;
  }
  .advisory-item-1,
  .advisory-item-2 {
    grid-row: 1/-1;
  }
  .advisory-item-5 {
    background: #b8d6ff;
  }
  .third-section-drip-irrigation {
    margin-top: 20px;
  }
  .third-section-drip-irrigation .drip-irrigation-para {
    margin-bottom: 15px;
    font-weight: bold;
  }
  .third-section-drip-irrigation .left-bottom {
    margin-top: 25px;
    display: flex;
    width: 63%;
    justify-content: space-between;
  }
  .third-section-drip-irrigation .left-bottom .old-percent-bottom {
    font-size: 20px;
    margin-top: 5px;
    font-weight: bold;
  }
  .third-section-drip-irrigation .left-bottom .new-percent-bottom {
    font-size: 20px;
    margin-top: 5px;
    color: #079f4e;
    font-weight: bold;
  }
  .current-runoff-supply {
    margin-top: 65px;
  }
  .current-runoff-supply p:nth-of-type(2) {
    font-size: 20px;
    margin-top: 15px;
    font-weight: bold;
  }
  .more-runoff p:nth-of-type(2) {
    color: #079f4e;
    font-weight: bold;
  }
  
  /* ADDED NEW  */
  .noAdvisoryWrapper {
    margin-top: 1.5vw;
    margin-left: 18%;
    margin-right: 40px;
  }
  
  .publishCropDiv {
    position: absolute;
    right: 0px;
    display: flex;
    top: -7px;
  }
  
  .advisoryCropDivTooltip {
    position: absolute;
    right: 20px;
    display: flex;
    top: -7px;
    cursor: pointer;
  }
  
  .toolTipIconAd {
    width: 30px;
    height: 30px;
  }
  
  /* MEDIA QUERIES STARTS FROM HERE  */
  @media screen and (max-width: 1250px) {
    .top-section-demand .demand-heading p {
      font-size: 16px;
    }
    .top-section-demand .demand-tcm .tcm-amount {
      font-size: 20px;
    }
    .top-section-demand .demand-tcm .tcm-amount img {
      height: 12px;
      width: 12px;
    }
    .top-section-demand .demand-tcm .tcm-amount-percent {
      font-size: 11px;
    }
    .second-section-overall-change .drip-percent > p {
      font-size: 15px;
    }
    .second-section-overall-change .overall-change-para {
      font-size: 15px;
    }
    .second-section-overall-change .change-in-drip .overall-amount-para {
      font-size: 18px;
    }
    .second-section-overall-change .current-drip-percent {
      font-size: 18px;
    }
    .third-section-drip-irrigation .drip-irrigation-para {
      font-size: 15px;
    }
    .third-section-drip-irrigation .left-bottom .old-percent-bottom {
      font-size: 18px;
    }
    .third-section-drip-irrigation .left-bottom .new-percent-bottom {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 1000px) {
    .advisory-preview-container {
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (min-width: 1700px) {
    .advisory-preview-container {
      grid-template-rows: 215px 215px 215px;
    }
  }
  
  /* MOBILE SCREENS  */
  
  @media screen and (max-width: 768px) {
    .advisory-preview-wrapper {
      position: relative;
      margin-top: 1.5vw;
      margin-left: 18px;
      margin-right: 18px;
    }
  
    .noAdvisoryWrapper {
      margin-top: 10px;
      margin-left: 18px;
      margin-right: 18px;
    }
  
    .top-section-demand {
      font-size: 0.8rem;
    }
  
    .top-section-demand .demand-heading p {
      font-size: 0.85rem;
    }
    .top-section-demand .demand-tcm .tcm-amount {
      font-size: 1rem;
    }
    .top-section-demand .demand-tcm .tcm-amount img {
      height: 11px;
      width: 11px;
    }
    .top-section-demand .demand-tcm .tcm-amount-percent {
      font-size: 0.65rem;
    }
    .second-section-overall-change .drip-percent > p {
      font-size: 0.8rem;
    }
    .second-section-overall-change .overall-change-para {
      font-size: 0.8rem;
    }
    .second-section-overall-change .change-in-drip .overall-amount-para {
      font-size: 0.9rem;
      /* font-size: 18px; */
    }
    .second-section-overall-change .current-drip-percent {
      /* font-size: 18px; */
      font-size: 0.9rem;
    }
    .third-section-drip-irrigation .drip-irrigation-para {
      /* font-size: 15px; */
      font-size: 0.85rem;
    }
    .third-section-drip-irrigation .left-bottom .old-percent-bottom {
      font-size: 0.85rem;
    }
    .third-section-drip-irrigation .left-bottom .new-percent-bottom {
      font-size: 0.85rem;
    }
  
    .third-section-drip-irrigation .left-bottom {
      font-size: 0.7rem;
    }
  
    .current-runoff-supply {
      margin-top: 10px;
      font-size: 0.75rem;
    }
  
    .current-runoff-supply p:nth-of-type(2) {
      font-size: 0.85rem;
    }
  
    .advisoryitems > div > p {
      font-size: 0.85rem;
    }
  
    .advisory-item-5 {
      margin-bottom: 10px;
    }
  
    .advisoryOnHeading {
      font-size: 0.85rem;
    }
  
    .backToScenario {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 0.8rem;
      cursor: unset;
      display: flex;
      justify-content: space-between;
    }
  
    .advisory-preview-container {
      margin-top: unset;
    }
  
    .publishCropDiv {
      position: relative;
      right: 0px;
      display: flex;
      top: unset;
    }
  
    .advisoryCropDivTooltip {
      position: relative;
      right: 6px;
      display: flex;
      top: unset;
      cursor: unset;
    }
  
    .resetsave button {
      padding: 4px 22px;
      font-size: 0.75rem;
    }
  
    .advisoryPublished {
      font-size: 0.85rem;
    }
  
    .toolTipIconAd {
      width: 25px;
      height: 25px;
    }
  }
  