.homePageTalukaHead {
  margin-top: 2%;
  margin-bottom: 1%;
  font-weight: 600;
}

.homeTalukaStats {
  width: 100%;
  height: 330px;
  margin-top: 1%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

@media screen and (max-width: 1250px) {
  .homeTalukaStats {
    height: 260px;
  }
  .homePageTalukaHead {
    margin-top: 0%;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .homeTalukaStats {
    height: 280px;
  }
  .homePageTalukaHead {
    margin-top: 1%;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1700px) {
  .homeTalukaStats {
    height: 350px;
  }
  .homePageTalukaHead {
    margin-top: 3%;
    font-size: 1.05rem;
  }
}

@media screen and (min-width: 1900px) {
  .homeTalukaStats {
    height: 420px;
  }
  .homePageTalukaHead {
    margin-top: 4%;
    font-size: 1.2rem;
  }
}

/* Adding breakpoints for mobile view  */
@media only screen and (max-width: 600px) {
  .homeTalukaStats {
    height: auto;
  }

  .homePageTalukaHead {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    margin-bottom: 0.5rem;
  }
}
