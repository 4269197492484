.left-side-parent-wrapper {
  margin-bottom: 30px;
  margin-top: 20px;
}

.first-and-second-item-container {
  margin-right: 16px;
}

.plan-grid-item {
  background: #fff;
}

.item1 {
  border-radius: 20px;
}

.left-toppest {
  display: flex;
}

.toppest-threes {
  width: 33.33%;
  padding: 12px;
}
.toppest-threes p:nth-of-type(1) {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 20px;
}
.toppest-threes p:nth-of-type(2) {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
}
.toppest-threes-one {
  border-top-left-radius: 20px;
  background: rgba(141, 141, 141, 0.4);
}
.toppest-threes-two {
  background: rgba(56, 131, 230, 0.31);
}
.toppest-threes-three {
  border-top-right-radius: 20px;
}

.left-current-text {
  font-size: 15px;
}

.planChart {
  height: 220px;
  padding: 0 50px;
  position: relative;
}

.percentageIncreaseBalance {
  bottom: 75px;
}

.percentageIncreaseAvailable {
  bottom: 116px;
}

.percentageIncreaseDemand {
  bottom: 160px;
}

.plan-chart-legends {
  display: flex;
  justify-content: center;
  margin-top: 18px;
  align-items: center;
  margin-bottom: 12px;
}

.plan-chart-legends p {
  font-size: 12px;
}

.item2 {
  border-radius: 12px;
}
.item2 .left-last .last-container {
  display: flex;
}
.item2 .left-last .last-container .months-remaining-text {
  font-size: 20px;
}
.item2 .left-last .last-container .last-container-one,
.item2 .left-last .last-container .last-container-two {
  width: 50%;
  padding: 12px;
}
.item2 .left-last .last-container .last-container-two {
  background: rgba(0, 110, 255, 0.28);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

/* new added  */
.plan-legends-span {
  height: 18px;
  width: 18px;
  background-color: rgba(141, 141, 141, 1);
  margin-right: 5px;
}

.graph-heading {
  margin-bottom: 15px;
}

.percentageLegends {
  width: 17px;
  height: 17px;
}

/* MEDIA QUERIES STARTS FROM HERE  */

@media screen and (max-width: 1300px) {
  .toppest-threes p:nth-of-type(1) {
    font-size: 14px;
  }
  .toppest-threes p:nth-of-type(2) {
    font-size: 16px;
  }

  .graph-heading p {
    font-size: 14px;
  }

  .left-current-text {
    font-size: 14px;
  }

  .plan-chart-legends p {
    font-size: 11px;
  }

  .left-last .last-container p {
    font-size: 14px;
  }
  .left-last .last-container .months-remaining-text {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1360px) {
}

@media screen and (max-width: 1175px) {
  .left-side-parent-wrapper {
    margin-bottom: unset;
  }

  .first-and-second-item-container {
    margin-right: unset;
  }

  .item2 {
    display: none;
  }
}

@media screen and (min-width: 1700px) {
  .planChart {
    height: 320px;
  }
  .percentageIncreaseBalance {
    bottom: 95px;
  }

  .percentageIncreaseAvailable {
    bottom: 170px;
  }

  .percentageIncreaseDemand {
    bottom: 235px;
  }

  .left-last .last-container {
    height: 140px;
  }
}

/* mobile screens starts from here */
@media screen and (max-width: 600px) {
  .left-side-parent-wrapper {
    margin-top: 0px;
  }

  .toppest-threes p:nth-of-type(1) {
    margin-bottom: 5px;
    font-size: 0.75rem;
  }
  .toppest-threes p:nth-of-type(2) {
    margin-bottom: 10px;
    font-size: 0.8rem;
  }

  .graph-heading {
    margin-bottom: 10px;
  }

  .graph-heading p {
    font-size: 0.75rem;
  }

  .plan-chart-legends {
    display: none;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 12px;
  }

  .plan-legends-span {
    height: 12px;
    width: 12px;
    background-color: rgba(141, 141, 141, 1);
    margin-right: 5px;
  }

  .planChart {
    height: 190px;
  }

  .percentageLegends {
    width: 14px;
    height: 14px;
  }

  .plan-chart-legends p {
    font-size: 0.6rem;
  }

  .planChart {
    padding: 0 45px;
  }

  .percentageIncreaseBalance {
    bottom: 70px;
  }

  .percentageIncreaseAvailable {
    bottom: 100px;
  }

  .percentageIncreaseDemand {
    bottom: 145px;
  }
}
