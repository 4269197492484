.surfacewaterPieLegend {
  font-size: 0.8rem;
}

/* // MEDIA QUERIES STARTS FROM HERE */

@media screen and (max-width: 1250px) {
  .surfacewaterPieLegend {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .surfacewaterPieLegend {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1700px) {
  .surfacewaterPieLegend {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1900px) {
  .surfacewaterPieLegend {
    font-size: 1.1rem;
  }
}
