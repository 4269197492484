.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top */
  height: 100vh;
}

.popupContent {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 90%; /* Responsive width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.closeButton {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
