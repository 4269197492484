.parent .btn {
  position: relative;
  display: inline-block;
  text-align: center;
  color: #a6a7a7;
  border: 2px solid #a6a7a7;
  cursor: pointer;
  transition: ease-out 0.5s;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  border-radius: 0;
}

.parent .btn.btn-border-1::after,
.btn.btn-border-1::before {
  position: absolute;
  content: '';
  width: 0%;
  height: 0%;
  visibility: hidden;
}

.btn.btn-border-1::after {
  bottom: -2px;
  right: -2px;
  border-left: 2px solid #3467ee;
  border-bottom: 2px solid #3467ee;
  transition: width 0.1s ease 0.1s, height 0.1s ease, visibility 0s 0.2s;
}

.btn.btn-border-1::before {
  top: -2px;
  left: -2px;
  border-top: 2px solid #3467ee;
  border-right: 2px solid #3467ee;
  transition: width 0.1s ease 0.3s, height 0.1s ease 0.2s, visibility 0s 0.4s;
}

.btn.btn-border-1:hover {
  animation: pulse 1s ease-out 0.4s;
  color: #3467ee !important;
  /* backgound: #3467ee !important; */
}

.btn.btn-border-1:hover::after,
.btn.btn-border-1:hover::before {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  visibility: visible;
  transition: width 0.1s ease 0.2s, height 0.1s ease 0.3s, visibility 0s 0.2s;
}

.btn.btn-border-1:hover::after {
  transition: width 0.1s ease 0.2s, height 0.1s ease 0.3s, visibility 0s 0.2s;
}

.btn.btn-border-1:hover::before {
  transition: width 0.1s ease, height 0.1s ease 0.1s;
}

.parent .programTitle {
  font-size: 0.9rem;
}

@media screen and (max-width: 1250px) {
  .programTitle {
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .programTitle {
    font-size: 0.85rem;
  }
}

@media screen and (min-width: 1700px) {
  .programTitle {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1900px) {
  .programTitle {
    font-size: 1.1rem;
  }
}
